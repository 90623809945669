import React, {useCallback, useEffect, useMemo, useRef, useState} from 'react'
import {
  FlatList,
  KeyboardAvoidingView,
  Pressable,
  StyleSheet,
  Text,
  View,
} from 'react-native'
import {useSafeAreaInsets} from 'react-native-safe-area-context'
import {LinearGradient} from 'expo-linear-gradient'
import {ProfileViewDetailed} from '@atproto/api/dist/client/types/app/bsky/actor/defs'
import {Circle_ref} from '@atproto/api/dist/client/types/com/atproto/server/getCircleList'
import {User} from '@atproto/api/dist/client/types/com/atproto/server/getSharedFriends'
import {msg, Trans} from '@lingui/macro'
import {useLingui} from '@lingui/react'
import {useNavigation} from '@react-navigation/native'

import {usePalette} from '#/lib/hooks/usePalette'
import {useWebMediaQueries} from '#/lib/hooks/useWebMediaQueries'
import {NavigationProp} from '#/lib/routes/types'
import {isIOS} from '#/platform/detection'
import {useModalControls} from '#/state/modals'
import {useAgent} from '#/state/session'
import {EarthIcon, UserIcon} from '#/view/icons/FeedIcons'
import {formatHandler} from '#/screens/Onboarding/util'
import {atoms as a, useTheme} from '#/alf'
import {Button, ButtonText} from '#/components/Button'
import {TabBar} from '../pager/TabBar'
import {UserAvatar} from '../util/UserAvatar'
import {getAvatartConfig} from '../util/UserTextAvatar'

export const snapPoints = ['90%']

export const Component = function DetectLinkedWalletModalComponent({
  id,
  hideAuthor,
}: {
  id: string
  hideAuthor: boolean
}) {
  const {_} = useLingui()
  const [currentItem, setCurrentItem] = useState<number>(0)
  const {isMobile, isDesktop} = useWebMediaQueries()
  const flatListRef = useRef<FlatList<any>>(null)
  const flatListRef2 = useRef<FlatList<any>>(null)
  const [userProfile, setUserProfile] = useState<ProfileViewDetailed>()
  const [friends, setFriends] = useState<User[]>([])
  const [circles, setCircles] = useState<Circle_ref[]>([])
  const pal = usePalette('default')
  const t = useTheme()
  const agent = useAgent()
  const {closeModal} = useModalControls()

  const tabs = useMemo(() => {
    return [
      _(msg`Mutual circle`) + `(${circles.length})`,
      _(msg`Mutual friend`) + `(${friends.length ? friends.length - 1 : 0})`,
    ]
  }, [_, circles.length, friends.length])

  const getUserProfile = useCallback(async () => {
    const res2 = await agent.getProfiles({actors: [id]})
    setUserProfile(res2.data.profiles[0])
  }, [agent, id])

  const getFriends = useCallback(async () => {
    try {
      const res = await agent.com.atproto.server.getSharedFriends({
        sipz_id_other: id,
      })
      setFriends(res.data.users || [])
    } catch (error) {}
  }, [agent, id])

  const getCircles = useCallback(() => {
    agent.com.atproto.server.getSharedCircles({sipz_id_other: id}).then(res => {
      if (res.data.result && res.data.result.length > 0) {
        setCircles(res.data.result)
      }
    })
  }, [agent.com.atproto.server, id])

  const init = useCallback(() => {
    getCircles()
    getUserProfile()
    getFriends()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    init()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  function useKeyboardVerticalOffset() {
    const {top} = useSafeAreaInsets()
    if (!isIOS) return 0
    if (top === 20) return 40
    return top + 10
  }
  const keyboardVerticalOffset = useKeyboardVerticalOffset()

  useEffect(() => {
    flatListRef.current?.scrollToOffset({offset: 0, animated: false})
    flatListRef2.current?.scrollToOffset({offset: 0, animated: false})
  }, [currentItem])

  return (
    <KeyboardAvoidingView
      behavior={isIOS ? 'padding' : 'height'}
      keyboardVerticalOffset={keyboardVerticalOffset}
      style={[
        a.flex_1,
        {minHeight: 300, paddingHorizontal: isMobile ? 10 : 0},
        isDesktop && {marginHorizontal: -10, marginTop: -10},
        t.atoms.modalBg,
      ]}>
      {hideAuthor ? (
        // hideAuthor modal========
        <>
          <View style={{height: 96, paddingLeft: 16}}>
            {userProfile ? (
              <FriendItem head hideAuthor item={{}} />
            ) : (
              <View
                style={{
                  flexDirection: 'row',
                  alignContent: 'center',
                  marginTop: 20,
                }}>
                <UserAvatar size={48} avatar={''} style={{marginRight: 10}} />
              </View>
            )}
          </View>
          <Text style={t.atoms.text}>
            <Trans>
              The spiller has enabled anonymous mode, so only the number of
              mutual friends and circles can be viewed.
            </Trans>
          </Text>
          <View
            style={{
              flexDirection: 'row',
              justifyContent: 'space-between',
              alignItems: 'center',
              marginTop: 10,
              marginBottom: 50,
            }}>
            <View style={[styles.anonymousInfoItem, {paddingRight: 6}]}>
              <View style={styles.infoCard}>
                <View
                  style={{flexDirection: 'row', alignItems: 'center', gap: 5}}>
                  <UserIcon size={24} style={{marginLeft: 10}} />
                  <Text
                    style={[
                      {
                        fontSize: 14,
                        fontWeight: '500',
                        color: pal.colors.text,
                        lineHeight: 24,
                      },
                      t.atoms.text,
                    ]}>
                    {circles.length}
                  </Text>
                </View>
                <Text style={t.atoms.text}>
                  <Trans>Mutual Friends</Trans>
                </Text>
              </View>
            </View>
            <View style={[styles.anonymousInfoItem, {paddingLeft: 6}]}>
              <View style={styles.infoCard}>
                <View
                  style={{flexDirection: 'row', alignItems: 'center', gap: 5}}>
                  <EarthIcon size={24} style={{marginLeft: 10}} />
                  <Text
                    style={[
                      {
                        fontSize: 14,
                        fontWeight: '500',
                        color: pal.colors.text,
                        lineHeight: 24,
                      },
                      t.atoms.text,
                    ]}>
                    {friends.length ? friends.length - 1 : 0}
                  </Text>
                </View>
                <Text style={t.atoms.text}>
                  <Trans>Mutual Circles</Trans>
                </Text>
              </View>
            </View>
          </View>
        </>
      ) : (
        // normal modal============
        <>
          <View style={{height: 96, paddingLeft: 16}}>
            {userProfile ? (
              <FriendItem
                head
                item={{
                  did: userProfile.did,
                  handle_name: userProfile.handle,
                  avatar: userProfile.avatar,
                }}
              />
            ) : (
              <View
                style={{
                  flexDirection: 'row',
                  alignContent: 'center',
                  marginTop: 20,
                }}>
                <UserAvatar size={48} avatar={''} style={{marginRight: 10}} />
              </View>
            )}
          </View>

          <TabBar
            onPressSelected={setCurrentItem}
            selectedPage={currentItem}
            onSelect={setCurrentItem}
            testID={'betScoreModalTab'}
            items={tabs}
            style={[
              t.atoms.modalBg,
              {borderBottomWidth: 1, borderBlockColor: '#97979733'},
            ]}
            selectedTextStyle={{color: pal.colors.text}}
            textStyle={{color: t.palette.black_25, fontWeight: '400'}}
            hideBorder={true}
            isCentered={true}
          />

          <View style={{height: 300, paddingLeft: 16, marginTop: 10}}>
            {currentItem === 0 ? (
              <FlatList
                style={{height: '100%'}}
                data={circles}
                renderItem={data => {
                  return <CircleItem item={data.item} />
                }}
                keyExtractor={item => `${item.id}`}
              />
            ) : (
              <FlatList
                style={{height: '100%'}}
                data={friends}
                renderItem={data => {
                  return <FriendItem item={data.item} />
                }}
                keyExtractor={item => item.handle}
              />
            )}
          </View>
        </>
      )}

      <Button
        style={[{width: '100%'}, a.self_start, a.mt_md]}
        label="circle detail"
        color="secondary"
        variant="solid"
        size="small"
        onPress={closeModal}>
        <ButtonText>
          <Trans>Close</Trans>
        </ButtonText>
      </Button>
    </KeyboardAvoidingView>
  )
}

function FriendItem({
  item,
  head,
  hideAuthor,
}: {
  item: User
  head?: boolean
  hideAuthor?: boolean
}) {
  const {_} = useLingui()
  const name = useMemo(() => {
    if (hideAuthor) {
      return _(msg`Anonymous`)
    }
    if (item?.user_name) {
      return item?.user_name
    } else {
      return formatHandler(item.handle_name || '')
    }
  }, [_, hideAuthor, item.handle_name, item?.user_name])

  const t = useTheme()

  return (
    <View style={{flexDirection: 'row', alignContent: 'center', marginTop: 20}}>
      <UserAvatar
        size={head ? 48 : 44}
        avatar={hideAuthor ? '' : item.avatar}
        style={{marginRight: 10}}
      />

      <View
        style={{
          justifyContent: hideAuthor ? 'center' : 'space-between',
          flex: 1,
        }}>
        <Text style={[t.atoms.text, {fontWeight: 'bold', fontSize: 14}]}>
          {name}
        </Text>
        {!hideAuthor && (
          <Text style={{marginTop: 5, color: '#98989F'}}>
            @{formatHandler(item.handle_name || '')}
          </Text>
        )}
      </View>
    </View>
  )
}

function CircleItem({item}: {item: Circle_ref}) {
  const avatarBg = getAvatartConfig(item?.id || 'sipzdid')
  const navigation = useNavigation<NavigationProp>()
  const t = useTheme()
  const jump = useCallback(() => {
    navigation.navigate('CircleDetail', {id: item?.id ? `${item.id}` : ''})
  }, [item.id, navigation])
  return (
    <Pressable
      accessibilityRole="button"
      style={{flexDirection: 'row', alignContent: 'center', marginTop: 20}}
      onPress={jump}>
      <View
        style={[
          {
            marginRight: 20,
            height: 56,
            width: 56,
            borderRadius: 10,
            backgroundColor: 'pink',
            overflow: 'hidden',
          },
        ]}>
        <LinearGradient
          colors={avatarBg}
          start={{x: 0.5, y: 0}}
          end={{x: 0.5, y: 1}}>
          <UserAvatar
            style={[a.align_center, a.justify_center]}
            type="circle"
            avatar={item.photo}
            size={56}
          />
        </LinearGradient>
      </View>

      <View style={{justifyContent: 'space-between', flex: 1}}>
        <Text style={[{fontWeight: 'bold', fontSize: 14}, t.atoms.text]}>
          {item.circle_name}
        </Text>
        <Text style={{marginTop: 5, color: '#98989F', marginBottom: 5}}>
          {item.member_count} Members
        </Text>
      </View>
    </Pressable>
  )
}

const styles = StyleSheet.create({
  anonymousInfoItem: {
    width: '50%',
    height: 80,
    marginTop: 16,
  },
  infoCard: {
    height: 80,
    borderWidth: 1,
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: 10,
    borderColor: '#979797',
    gap: 10,
  },
})
