import React, {useMemo} from 'react'
import {StyleSheet, View} from 'react-native'
import MasonryList from '@react-native-seoul/masonry-list'

import {usePalette} from '#/lib/hooks/usePalette'
import {useWebMediaQueries} from '#/lib/hooks/useWebMediaQueries'
import {FeedPostSlice, FeedPostSliceItem} from '#/state/queries/post-feed'
import {useSession} from '#/state/session'
import {Visibility} from '../../util/List.web'
import {ScrollView} from '../../util/ScrollView.web'
import {FeedItem} from '../Feed'
import {OperationFeedItem} from '../OperationFeedItem'
import {BigFigureCardItem} from './BigFigureCard'

interface IExploreFeedList {
  data: FeedItem[]
  onEndReached: (data: {distanceFromEnd: number}) => void
  headerOffset?: number
  refreshing?: boolean
  onRefresh?: () => void
  feedParams?: string
  webFresh?: boolean
}

export default function BigFigureFeedList({
  data,
  onEndReached,
  refreshing: _unsupportedRefreshing,
  onRefresh: _unsupportedOnRefresh = undefined,
  feedParams,
  webFresh,
}: // headerOffset,
IExploreFeedList) {
  const {isDesktop, isTablet, isMobile} = useWebMediaQueries()
  const pal = usePalette('default')
  const {hasSession} = useSession()
  const listColumn = isDesktop ? 2 : 1

  const arrayData = useMemo((): FeedPostSliceItem[] => {
    const array = data.map(ele => {
      if (ele.type === 'slice') {
        return ele.slice.items
      } else {
        return []
      }
    })
    return array.flat()
  }, [data])

  const renderItem = ({item, i}: {item: FeedPostSliceItem; i: number}) => {
    if (item.record.operation) {
      return (
        <OperationFeedItem
          slice={
            {
              items: [item],
            } as FeedPostSlice
          }
          hideTopBorder={true}
        />
      )
    }
    return (
      <BigFigureCardItem
        key={item._reactKey}
        post={item.post}
        reason={undefined}
        feedContext={undefined}
        record={item.record}
        moderation={item.moderation}
        parentAuthor={item.parentAuthor}
        showReplyTo={i === 0}
        isThreadParent={
          arrayData?.length === 1 ? false : i < arrayData.length - 1
        }
        isThreadChild={arrayData.length === 1 ? false : i > 0}
        isThreadLastChild={
          (arrayData.length === 1 ? false : i > 0) && arrayData.length === i + 1
        }
        isParentBlocked={item.isParentBlocked}
        hideTopBorder={i === 0}
      />
    )
  }

  return (
    <View
      style={[
        pal.border,
        isDesktop && styles.desktopContainer,
        isTablet && styles.tabletContainer,
        isMobile && {marginTop: hasSession ? 85 : 43, paddingBottom: 95},
      ]}>
      <ScrollView
        refreshing={_unsupportedRefreshing || false}
        handleRefresh={_unsupportedOnRefresh}
        scene={feedParams}
        webFresh={webFresh}>
        <MasonryList
          keyExtractor={item => item._reactKey}
          ListHeaderComponent={<View />}
          onEndReached={() => {
            console.log('onEndReached')
            // onEndReached({distanceFromEnd: headerOffset ?? 0})
          }}
          numColumns={listColumn}
          data={arrayData}
          // @ts-ignore
          renderItem={renderItem}
        />
        <View style={{width: 23, height: 90}} />
        <Visibility
          onVisibleChange={isVisible => {
            isVisible && onEndReached({distanceFromEnd: 0})
          }}
        />
      </ScrollView>
    </View>
  )
}

const styles = StyleSheet.create({
  desktopContainer: {
    maxWidth: 828,
    height: '100%',
    // @ts-ignore
    left: 'calc(50vw - 312px)',
    borderLeftWidth: 1,
    paddingLeft: 8,
  },
  tabletContainer: {
    maxWidth: 600,
    marginLeft: 'auto',
    marginRight: 'auto',
    borderLeftWidth: 1,
    borderRightWidth: 1,
  },
})
