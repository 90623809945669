import {useMemo, useSyncExternalStore} from 'react'

import {useAgent} from '#/state/session'
import * as Toast from '#/view/com/util/Toast'
import {createStore} from './SdlStore'

const store = createStore({
  twitterUserId: '',
  twitterUserName: '',
})

const {subscribe, getState, setState: setTwStoreState} = store

export function useTwStore() {
  const agent = useAgent()
  const twStore = useSyncExternalStore(subscribe, getState)
  const getTwitterInfo = () => {
    agent.com.atproto.server.getBindTwitter().then(async res => {
      if (res.data.twitterUserId && res.data.twitterUserName) {
        setTwStoreState({
          twitterUserId: res.data.twitterUserId,
          twitterUserName: res.data.twitterUserName,
        })
      }
    })
  }

  const isBoundTw = useMemo(() => {
    return !!twStore.twitterUserId
  }, [twStore])

  const resetTwStore = () => {
    setTwStoreState({
      twitterUserId: '',
      twitterUserName: '',
    })
  }

  const bindTwitter = (info: {
    twitterUserId: string
    twitterUserName: string
  }) => {
    setTwStoreState(info)
  }

  const unlinkTw = () => {
    agent.com.atproto.server.unlinkTwitter().then(() => {
      resetTwStore()
      Toast.show('Twitter has been unlinked')
    })
  }

  return {
    twStore,
    getTwitterInfo,
    resetTwStore,
    unlinkTw,
    bindTwitter,
    isBoundTw,
  }
}
