import React, {memo, useCallback} from 'react'
import {StyleProp, TextStyle, View, ViewStyle} from 'react-native'
import {AppBskyActorDefs, ModerationDecision, ModerationUI} from '@atproto/api'
import {useQueryClient} from '@tanstack/react-query'

import {precacheProfile} from '#/state/queries/profile'
import {usePalette} from 'lib/hooks/usePalette'
import {makeProfileLink} from 'lib/routes/links'
import {forceLTR} from 'lib/strings/bidi'
import {sanitizeDisplayName} from 'lib/strings/display-names'
import {TypographyVariant} from 'lib/ThemeContext'
import {BigFigureCardHeaderBg} from '#/view/icons/FeedIcons'
import {formatHandler} from '#/screens/Onboarding/util'
import {atoms as a} from '#/alf'
import {getIsOpreration} from '../../util/access/OperationUtil'
import {TextLinkOnWebOnly} from '../../util/Link'
import {Text} from '../../util/text/Text'
import {AnonSpillerAvatar, PreviewableUserAvatar} from '../../util/UserAvatar'
import {VipIcon} from '../OperationFeedItem'

interface PostMetaOpts {
  author: AppBskyActorDefs.ProfileViewBasic
  moderation: ModerationDecision | undefined
  authorHasWarning: boolean
  showAvatar?: boolean
  avatarModeration?: ModerationUI
  avatarSize?: number
  displayNameType?: TypographyVariant
  displayNameStyle?: StyleProp<TextStyle>
  onOpenAuthor?: () => void
  style?: StyleProp<ViewStyle>
  rightButton?: () => React.ReactNode
  isAnonymous?: boolean
  isQuoted?: boolean
}

let BigFigurePostMeta = (opts: PostMetaOpts): React.ReactNode => {
  const pal = usePalette('default')
  const displayName =
    opts.author.displayName || formatHandler(opts.author.handle)
  const profileLink = makeProfileLink(opts.author)
  const queryClient = useQueryClient()
  const onOpenAuthor = opts.onOpenAuthor
  const onBeforePressAuthor = useCallback(() => {
    precacheProfile(queryClient, opts.author)
    onOpenAuthor?.()
  }, [queryClient, opts.author, onOpenAuthor])

  const isOperation = getIsOpreration(opts.author.did)

  return (
    <View
      style={[
        a.flex_row,
        a.flex_1,
        a.gap_xs,
        opts.style,
        a.relative,
        {alignItems: 'flex-start', zIndex: 1},
      ]}>
      <BigFigureCardHeaderBg style={[a.absolute, {top: -16, left: -10}]} />
      {opts.showAvatar &&
        (opts?.isAnonymous ? (
          <AnonSpillerAvatar
            size={opts.avatarSize || 34}
            profile={opts.author}
          />
        ) : (
          <View style={{alignSelf: 'center'}}>
            <PreviewableUserAvatar
              size={opts.avatarSize || 34}
              profile={opts.author}
              moderation={opts.avatarModeration}
              type={opts.author.associated?.labeler ? 'labeler' : 'user'}
            />
          </View>
        ))}
      <View>
        {/* <Text style={[{fontSize: 13}, t.atoms.text_sub]}>
          {opts?.isQuoted ? (
            <Trans>Quoted by</Trans>
          ) : (
            <Trans>Spilled by</Trans>
          )}
        </Text> */}
        <Text
          numberOfLines={1}
          style={[
            a.flex_1,
            pal.textLight,
            opts.displayNameStyle,
            isOperation && {display: 'flex'},
            {marginTop: 6},
          ]}>
          {opts?.isAnonymous ? (
            <Text
              type={opts.displayNameType || 'lg-bold'}
              lineHeight={1.2}
              style={[pal.text, {fontSize: 15}]}>
              Anonymous
            </Text>
          ) : (
            <TextLinkOnWebOnly
              type={opts.displayNameType || 'lg-bold'}
              style={[pal.text, {fontSize: 15}]}
              lineHeight={1.2}
              disableMismatchWarning
              text={forceLTR(
                sanitizeDisplayName(
                  displayName,
                  opts.moderation?.ui('displayName'),
                ),
              )}
              href={profileLink}
              onBeforePress={onBeforePressAuthor}
            />
          )}
          {isOperation && (
            <View style={[a.ml_xs]}>
              <VipIcon />
            </View>
          )}
        </Text>
      </View>
    </View>
  )
}
BigFigurePostMeta = memo(BigFigurePostMeta)
export {BigFigurePostMeta}
