import React, {useCallback, useEffect, useRef, useState} from 'react'
import {
  Image as ImageRN,
  StyleSheet,
  Text,
  TouchableOpacity,
  View,
} from 'react-native'
import ViewShot, {captureRef} from 'react-native-view-shot'
import {LinearGradient} from 'expo-linear-gradient'
import {Trans} from '@lingui/macro'

import {IShareTea, useModalControls} from '#/state/modals'
import {shareUrl} from 'lib/sharing'
import {
  CloseShareIcon,
  DownLoadIcon,
  QuotationIcon,
  ShareLikeIcon,
  ShareLinkIcon,
  ShareMessageIcon,
  ShareRepostIcon,
} from '#/view/icons/ModalIcons'
import {useTheme} from '#/alf'
import {UserAvatar} from '../../util/UserAvatar'
import {DownLoadImage} from './ShareProfile'
import {ShareQRCode} from './ShareQRCode'

export const snapPoints = ['fullscreen']

export function Component({
  avatar,
  displayName,
  url,
  image,
  content,
  likeCount,
  repostCount,
  replyCount,
  target,
}: IShareTea) {
  const viewRef = useRef()
  const {closeModal} = useModalControls()
  const t = useTheme()

  const captureView = async () => {
    try {
      const uri = await captureRef(viewRef, {
        format: 'png',
        quality: 0.8,
      })
      await DownLoadImage(uri, displayName)
      closeModal()
    } catch (error) {
      console.error('Failed to capture view', error)
    }
  }

  const onPressLink = useCallback(() => {
    shareUrl(url)
    closeModal()
  }, [url, closeModal])

  const [imageUrl, setImageUrl] = useState('')
  const [postImageUrl, setPostImageUrl] = useState('')

  useEffect(() => {
    const fetchImage = async () => {
      try {
        if (avatar) {
          const response = await fetch(avatar)
          const blob = await response.blob()
          const imgUrl = URL.createObjectURL(blob)
          setImageUrl(imgUrl)
        }

        if (image) {
          const bgResponse = await fetch(image)
          const bgBlob = await bgResponse.blob()
          const bgUrl = URL.createObjectURL(bgBlob)
          setPostImageUrl(bgUrl)
        }
      } catch (error) {
        console.error('Error fetching image:', error)
      }
    }

    fetchImage()
  }, [avatar, image])

  return (
    <View style={styles.page}>
      <View style={{position: 'relative'}}>
        <Text style={[styles.title, t.atoms.text]}>
          <Trans>Share</Trans>
        </Text>
        <TouchableOpacity
          accessibilityRole="button"
          style={{position: 'absolute', left: -5, top: 0}}
          onPress={closeModal}>
          <CloseShareIcon color={t.palette.white} />
        </TouchableOpacity>
      </View>
      <View style={styles.out}>
        <ViewShot ref={viewRef} style={styles.viewShot}>
          <LinearGradient
            colors={['#DBFE0A', '#FCD010']}
            style={styles.outputBox}>
            <View style={styles.userInfo}>
              <View style={{borderWidth: 3, borderRadius: 100, width: 30}}>
                <UserAvatar avatar={imageUrl} size={24} />
              </View>
              <View>
                <Text
                  style={{fontSize: 16, fontWeight: 'bold', color: '#000000'}}>
                  {displayName}
                </Text>
              </View>
            </View>

            {postImageUrl && (
              <ImageRN
                style={{
                  width: '100%',
                  height: 200,
                  borderRadius: 10,
                  marginTop: 10,
                }}
                source={{uri: postImageUrl}}
                accessibilityIgnoresInvertColors={true}
              />
            )}

            <View style={styles.content}>
              <View style={{position: 'absolute', top: 0, left: 0}}>
                <QuotationIcon />
              </View>
              <Text
                numberOfLines={3}
                style={{
                  fontSize: 20,
                  fontWeight: 'bold',
                  color: '#000000',
                  fontStyle: 'italic',
                }}>
                {content}
              </Text>
            </View>

            {!!target && (
              <Text
                numberOfLines={1}
                style={{
                  width: '100%',
                  textAlign: 'left',
                  fontSize: 14,
                  fontWeight: 'bold',
                  color: '#000000',
                  marginBottom: 10,
                }}>
                {target}
              </Text>
            )}

            <View style={{width: '100%', flexDirection: 'row'}}>
              <View style={styles.iconSpace}>
                <ShareLikeIcon />
                {!!likeCount && (
                  <Text style={styles.iconText}>{likeCount}</Text>
                )}
              </View>
              <View style={styles.iconSpace}>
                <ShareMessageIcon />
                {!!replyCount && (
                  <Text style={styles.iconText}>{replyCount}</Text>
                )}
              </View>
              <View style={styles.iconSpace}>
                <ShareRepostIcon />
                {!!repostCount && (
                  <Text style={styles.iconText}>{repostCount}</Text>
                )}
              </View>
            </View>

            <View
              style={{
                width: '100%',
                gap: 10,
                flexDirection: 'row',
                justifyContent: 'space-between',
                alignItems: 'center',
              }}>
              <View style={{flex: 1, paddingTop: 10}}>
                <Text
                  style={{
                    color: '#000000',
                    fontSize: 16,
                    marginTop: 12,
                    marginBottom: 9,
                    fontWeight: 'bold',
                    fontFamily: 'Damion',
                  }}>
                  <Trans>Sip and Spill together!</Trans>
                </Text>
                <Text style={{color: '#191916', fontSize: 12}}>
                  https://sipz.io
                </Text>
              </View>

              <View style={{marginTop: 20}}>
                <ShareQRCode url={url} />
              </View>
            </View>
          </LinearGradient>
        </ViewShot>
      </View>

      <View style={styles.ctrl}>
        <TouchableOpacity
          accessibilityRole="button"
          style={styles.button}
          onPress={captureView}>
          <DownLoadIcon />
          <Text style={{color: t.palette.white}}>
            <Trans>Save to Device</Trans>
          </Text>
        </TouchableOpacity>
        <TouchableOpacity
          accessibilityRole="button"
          style={styles.button}
          onPress={onPressLink}>
          <ShareLinkIcon />
          <Text style={{color: t.palette.white}}>
            <Trans>Copy Link</Trans>
          </Text>
        </TouchableOpacity>
      </View>
    </View>
  )
}
const styles = StyleSheet.create({
  page: {
    width: '100%',
    height: 'auto',
    padding: 10,
    backgroundColor: 'transparent',
  },
  title: {
    fontSize: 19,
    textAlign: 'center',
    fontWeight: 'bold',
    marginBottom: 10,
  },
  iconSpace: {width: '30%', flexDirection: 'row', alignItems: 'center', gap: 8},
  iconText: {
    fontSize: 14,
    fontWeight: 'bold',
  },
  out: {
    justifyContent: 'center',
    alignItems: 'center',
    height: 'auto',
  },
  outputBox: {
    justifyContent: 'center',
    alignItems: 'center',
    width: 350,
    padding: 24,
    borderRadius: 16,
  },
  userInfo: {
    width: '100%',
    flexDirection: 'row',
    alignItems: 'center',
    gap: 10,
  },
  content: {
    position: 'relative',
    marginTop: 10,
    paddingTop: 30,
    paddingBottom: 16,
    width: '100%',
  },
  viewShot: {
    padding: 10,
    justifyContent: 'center',
    alignItems: 'center',
  },
  ctrl: {
    padding: 20,
    flexDirection: 'row',
    gap: 50,
    justifyContent: 'center',
  },
  button: {
    alignItems: 'center',
    gap: 10,
  },
})
