import React, {memo} from 'react'
import {
  // ImageBackground,
  StyleSheet,
  Text,
  TouchableWithoutFeedback,
  View,
} from 'react-native'
import {AppBskyActorDefs, ModerationDecision} from '@atproto/api'
import {FontAwesomeIcon} from '@fortawesome/react-native-fontawesome'
import {msg, Trans} from '@lingui/macro'
// import {FontAwesomeIcon} from '@fortawesome/react-native-fontawesome'
import {useLingui} from '@lingui/react'
import {useNavigation} from '@react-navigation/native'

import {track} from '#/lib/analytics/analytics'
import {useIsMe} from '#/lib/hooks/useIsMe'
import {useWebMediaQueries} from '#/lib/hooks/useWebMediaQueries'
import {logger} from '#/logger'
import {Shadow} from '#/state/cache/types'
import {ProfileImageLightbox, useLightboxControls} from '#/state/lightbox'
import {useModalControls} from '#/state/modals'
import {useFriendQueryIsFriend} from '#/state/queries/connections'
import {useMaybeConvoForUser} from '#/state/queries/messages/get-convo-for-members'
import {useProfileFollowMutationQueue} from '#/state/queries/profile'
import {useRequireAuth, useSession} from '#/state/session'
// import {BACK_HITSLOP} from 'lib/constants'
// import {useWebMediaQueries} from 'lib/hooks/useWebMediaQueries'
import {NavigationProp} from 'lib/routes/types'
import {sanitizeDisplayName} from 'lib/strings/display-names'
import {isIOS} from 'platform/detection'
import {getIsOpreration} from '#/view/com/util/access/OperationUtil'
import * as Toast from '#/view/com/util/Toast'
import {AddFriendsIcon} from '#/view/icons/ModalIcons'
import {
  LeftIcon,
  // MaskIcon,
  MessageIcon,
  // SettingIcon,
  UnFollowIcon,
  UpLoadIcon,
  // UpLoadIcon,
} from '#/view/icons/ProfileIcons'
import {LoadingPlaceholder} from 'view/com/util/LoadingPlaceholder'
import {UserAvatar} from 'view/com/util/UserAvatar'
import {UserBanner} from 'view/com/util/UserBanner'
import {formatHandler} from '#/screens/Onboarding/util'
import {atoms as a, useTheme} from '#/alf'
import {Audit} from '#/components/icons/Audit'
import {ArrowUpLeftIcon} from '#/components/icons/StakeIcons'
import {WalletIcon} from '#/components/icons/Wallet'
import {LabelsOnMe} from '#/components/moderation/LabelsOnMe'
import {ProfileHeaderAlerts} from '#/components/moderation/ProfileHeaderAlerts'

interface Props {
  profile: Shadow<AppBskyActorDefs.ProfileViewDetailed>
  moderation: ModerationDecision
  hideBackButton?: boolean
  isPlaceholderProfile?: boolean
}

let ProfileHeaderShell = ({
  children,
  profile,
  moderation,
  hideBackButton = false,
  isPlaceholderProfile,
}: React.PropsWithChildren<Props>): React.ReactNode => {
  const {isDesktop} = useWebMediaQueries()
  const t = useTheme()
  const {currentAccount} = useSession()
  const {_} = useLingui()
  const {openLightbox} = useLightboxControls()
  const navigation = useNavigation<NavigationProp>()
  const requireAuth = useRequireAuth()
  const [queueFollow, queueUnfollow] = useProfileFollowMutationQueue(
    profile,
    'ProfileHeader',
  )
  const {data: convo} = useMaybeConvoForUser(profile.did)
  // const {isDesktop} = useWebMediaQueries()
  const isMe = useIsMe(profile.did)
  const {data: isFriend} = useFriendQueryIsFriend(profile.did)
  const isCurrent = React.useMemo(() => {
    return profile.did === currentAccount?.did
  }, [currentAccount?.did, profile.did])

  const isOperation = getIsOpreration(currentAccount?.did || '')

  const onPressBack = React.useCallback(() => {
    if (navigation.canGoBack()) {
      navigation.goBack()
    } else {
      navigation.navigate('Home')
    }
  }, [navigation])

  // const onPressMask = React.useCallback(() => {
  //   navigation.navigate('Mask', {time: Date.now()})
  // }, [navigation])

  // const onPressSetting = React.useCallback(() => {
  //   navigation.navigate('MaskSetting', {handle: profile.handle})
  // }, [navigation, profile.handle])

  const onPressLinkedWallet = React.useCallback(() => {
    navigation.navigate('LinkedWallet')
  }, [navigation])

  const onPressAudit = React.useCallback(() => {
    navigation.navigate('AuditScreen')
  }, [navigation])

  const onPressMassage = React.useCallback(() => {
    if (convo) {
      navigation.navigate('Messages', {})
    }
  }, [convo, navigation])

  const onPressAvi = React.useCallback(() => {
    const modui = moderation.ui('avatar')
    if (profile.avatar && !(modui.blur && modui.noOverride)) {
      openLightbox(new ProfileImageLightbox(profile))
    }
  }, [openLightbox, profile, moderation])

  const onPressUnfollow = () => {
    requireAuth(async () => {
      try {
        track('ProfileHeader:UnfollowButtonClicked')
        await queueUnfollow()
        Toast.show(
          _(
            msg`No longer following ${sanitizeDisplayName(
              profile.displayName || profile.handle,
              moderation.ui('displayName'),
            )}`,
          ),
        )
      } catch (e: any) {
        if (e?.name !== 'AbortError') {
          logger.error('Failed to unfollow', {message: String(e)})
          Toast.show(_(msg`There was an issue! ${e.toString()}`), 'xmark')
        }
      }
    })
  }

  const {openModal} = useModalControls()

  const onShare = () => {
    openModal({
      name: 'share-profile',
      profile: {
        displayName: profile.displayName || formatHandler(profile.handle),
        avatar: profile.avatar || '',
        handle: profile.handle,
        description: profile.description || '',
      },
    })
  }

  const onPressFollow = () => {
    requireAuth(async () => {
      try {
        track('ProfileHeader:FollowButtonClicked')
        await queueFollow()
        Toast.show(
          _(
            msg`Following ${sanitizeDisplayName(
              profile.displayName || profile.handle,
              moderation.ui('displayName'),
            )}`,
          ),
        )
      } catch (e: any) {
        if (e?.name !== 'AbortError') {
          logger.error('Failed to follow', {message: String(e)})
          Toast.show(_(msg`There was an issue! ${e.toString()}`), 'xmark')
        }
      }
    })
  }

  const onAddFriend = () => {
    requireAuth(async () => {
      openModal({
        name: 'send-friends-request',
        selectedUser: {
          id: profile.did,
          source: 'profile',
        },
        from: 'profile',
        onCallBack: () => {},
      })
    })
  }

  return (
    <View style={t.atoms.bg} pointerEvents={isIOS ? 'auto' : 'box-none'}>
      <View pointerEvents={isIOS ? 'auto' : 'none'}>
        {isPlaceholderProfile ? (
          <LoadingPlaceholder
            width="100%"
            height={150}
            style={{borderRadius: 0}}
          />
        ) : (
          <UserBanner
            type={profile.associated?.labeler ? 'labeler' : 'default'}
            banner={profile.banner}
            moderation={moderation.ui('banner')}
          />
        )}
      </View>

      {children}

      {!isPlaceholderProfile && (
        <View
          style={[a.px_lg, a.py_xs]}
          pointerEvents={isIOS ? 'auto' : 'box-none'}>
          {isMe ? (
            <LabelsOnMe details={{did: profile.did}} labels={profile.labels} />
          ) : (
            <ProfileHeaderAlerts moderation={moderation} />
          )}
        </View>
      )}

      {/* top button ================ */}
      <View style={styles.backBtnWrapper}>
        {!hideBackButton && !isDesktop && (
          <TouchableWithoutFeedback
            accessibilityRole="button"
            onPress={onPressBack}>
            <View style={styles.sdlBt}>
              <LeftIcon size={25} />
            </View>
          </TouchableWithoutFeedback>
        )}
        <View
          style={{flexDirection: 'row', flex: 1, justifyContent: 'flex-end'}}>
          {isMe ? (
            <>
              {/* <TouchableWithoutFeedback
                accessibilityRole="button"
                onPress={onPressMask}>
                <View style={styles.sdlBt}>
                  <MaskIcon />
                </View>
              </TouchableWithoutFeedback> */}

              {isOperation && (
                <TouchableWithoutFeedback
                  accessibilityRole="button"
                  onPress={onPressAudit}>
                  <View style={styles.sdlBt}>
                    <Audit style={{color: 'white'}} />
                  </View>
                </TouchableWithoutFeedback>
              )}

              {isCurrent && (
                <TouchableWithoutFeedback
                  accessibilityRole="button"
                  onPress={onPressLinkedWallet}>
                  <View style={styles.sdlBt}>
                    <WalletIcon style={{color: 'white'}} />
                  </View>
                </TouchableWithoutFeedback>
              )}
            </>
          ) : profile.viewer?.following ? (
            <>
              <TouchableWithoutFeedback
                accessibilityRole="button"
                onPress={onPressUnfollow}>
                <View
                  style={[
                    styles.btnBg,
                    a.gap_xs,
                    {
                      flexDirection: 'row',
                      borderRadius: 40,
                      justifyContent: 'center',
                      alignItems: 'center',
                      cursor: 'pointer',
                      paddingHorizontal: 10,
                    },
                  ]}>
                  <FontAwesomeIcon icon={'check'} color={t.palette.white} />
                  <Text style={[styles.followBtText, {color: t.palette.white}]}>
                    <Trans>Subscribed</Trans>
                  </Text>
                </View>
              </TouchableWithoutFeedback>

              {convo && (
                <TouchableWithoutFeedback
                  accessibilityRole="button"
                  onPress={onPressMassage}>
                  <View style={styles.sdlBt}>
                    <MessageIcon />
                  </View>
                </TouchableWithoutFeedback>
              )}
            </>
          ) : (
            <TouchableWithoutFeedback
              accessibilityRole="button"
              onPress={onPressFollow}>
              <View
                style={[
                  styles.btnBg,
                  {
                    borderRadius: 40,
                    justifyContent: 'center',
                    alignItems: 'center',
                    cursor: 'pointer',
                  },
                ]}>
                <Text
                  style={[
                    styles.followBtText,
                    {marginHorizontal: 10, color: t.palette.white},
                  ]}>
                  <Trans>Subscribe</Trans>
                </Text>
              </View>
            </TouchableWithoutFeedback>
          )}
          {!isMe &&
            (!isFriend ? (
              <TouchableWithoutFeedback
                accessibilityRole="button"
                onPress={onAddFriend}>
                <View style={styles.sdlBt}>
                  <AddFriendsIcon
                    size={24}
                    color={t.palette.white}
                    style={{marginLeft: 2}}
                  />
                </View>
              </TouchableWithoutFeedback>
            ) : isFriend === 2 ? (
              <View
                style={[
                  styles.btnBg,
                  a.flex_row,
                  a.gap_xs,
                  a.px_md,
                  {height: 32, borderRadius: 40, marginLeft: 10},
                ]}>
                <ArrowUpLeftIcon
                  style={{marginTop: 2}}
                  primaryColor={t.palette.white}
                />
                <Text
                  style={[
                    a.text_xs,
                    a.font_semibold,
                    {color: t.palette.white},
                  ]}>
                  <Trans>Request sent</Trans>
                </Text>
              </View>
            ) : (
              <View style={styles.sdlBt}>
                <UnFollowIcon />
              </View>
            ))}

          <TouchableWithoutFeedback
            accessibilityRole="button"
            onPress={onShare}>
            <View style={styles.sdlBt}>
              <UpLoadIcon />
            </View>
          </TouchableWithoutFeedback>
        </View>
      </View>

      <TouchableWithoutFeedback
        testID="profileHeaderAviButton"
        onPress={onPressAvi}
        accessibilityRole="image"
        accessibilityLabel={_(msg`View ${profile.handle}'s avatar`)}
        accessibilityHint="">
        <View
          style={[
            t.atoms.bg,
            {borderColor: t.atoms.bg.backgroundColor},
            styles.avi,
            profile.associated?.labeler && styles.aviLabeler,
          ]}>
          <UserAvatar
            type={profile.associated?.labeler ? 'labeler' : 'user'}
            size={68}
            avatar={profile.avatar}
            moderation={moderation.ui('avatar')}
          />
        </View>
      </TouchableWithoutFeedback>
    </View>
  )
}
ProfileHeaderShell = memo(ProfileHeaderShell)
export {ProfileHeaderShell}

const styles = StyleSheet.create({
  backBtnWrapper: {
    position: 'absolute',
    top: 10,
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    width: '100%',
    height: 32,
    overflow: 'hidden',
    paddingEnd: 10,
  },
  backBtn: {
    width: 30,
    height: 30,
    borderRadius: 15,
    alignItems: 'center',
    justifyContent: 'center',
  },
  avi: {
    position: 'absolute',
    top: 129,
    left: 10,
    width: 72,
    height: 72,
    borderRadius: 47,
    borderWidth: 2,
  },
  aviLabeler: {
    borderRadius: 10,
  },
  sdlBt: {
    width: 32,
    height: 32,
    borderRadius: 100,
    marginStart: 10,
    backgroundColor: 'rgba(0,0,0,0.3)',
    alignItems: 'center',
    justifyContent: 'center',
    cursor: 'pointer',
  },
  btnBg: {
    backgroundColor: 'rgba(0,0,0,0.3)',
    alignItems: 'center',
    justifyContent: 'center',
  },
  followBt: {
    overflow: 'hidden',
    borderRadius: 15,
    justifyContent: 'center',
    cursor: 'pointer',
  },
  followBtText: {
    fontSize: 12,
    fontWeight: '500',
  },
})
