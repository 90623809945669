import React, {useState} from 'react'
import {StyleSheet, Text, View} from 'react-native'
import {msg, Trans} from '@lingui/macro'
import {useLingui} from '@lingui/react'
import {RouteProp, useNavigation, useRoute} from '@react-navigation/native'

import {usePalette} from '#/lib/hooks/usePalette'
import {CommonNavigatorParams, NavigationProp} from '#/lib/routes/types'
import {useAgent} from '#/state/session'
import {EmptyBox} from '#/view/com/util/EmptyBox'
import {TextLink} from '#/view/com/util/Link'
import * as Toast from '#/view/com/util/Toast'
import {ViewHeader} from '#/view/com/util/ViewHeader'
import {atoms as a, useTheme} from '#/alf'
import {Button, ButtonIcon, ButtonText} from '#/components/Button'
import * as Dialog from '#/components/Dialog'
import {Loader} from '#/components/Loader'
import {H2} from '#/components/Typography'

export default function CircleApply() {
  const t = useTheme()
  const pal = usePalette('default')
  const {_} = useLingui()
  const basic = Dialog.useDialogControl()
  const route = useRoute<RouteProp<CommonNavigatorParams, 'CircleApply'>>()
  const agent = useAgent()
  const navigation = useNavigation<NavigationProp>()

  const [loading, setLaoding] = useState<boolean>(false)

  const onApply = async () => {
    try {
      if (!loading) {
        setLaoding(true)
        await agent.com.atproto.server.circleApply({
          circle_id: +route.params.id,
        })
        Toast.show(_(msg`The request has been sent, please wait.`))
        setLaoding(false)
        navigation.goBack()
      }
    } catch (err: any) {
      Toast.show(_(msg`${err?.message}`))
      console.log(err?.message)
    } finally {
      setLaoding(false)
    }
  }

  return (
    <>
      <View style={[a.flex_1]}>
        <ViewHeader title={'Join Circle'} />
        <View style={[a.px_xl, a.flex_1]}>
          <EmptyBox icon="apply" message="" viewStyles={[a.pt_md]} />
          <H2
            style={[a.font_extrabold, a.mb_lg, {fontSize: 20, lineHeight: 30}]}>
            <Trans>Review and agree to the rules of a circle.</Trans>
          </H2>
          <View style={[a.mb_lg]}>
            <Text style={[t.atoms.text, {lineHeight: 21, fontSize: 14}]}>
              <Trans>
                These are set and enforced by Community admins and are in
                addition to.&nbsp;
                <TextLink
                  text={_(msg`our Rules`)}
                  style={pal.linkActive}
                  onPress={() => basic.open()}
                  href="#"
                />
                .
              </Trans>
            </Text>
          </View>
          <View style={[a.gap_md]}>
            <Text style={[styles.abilityItem, t.atoms.modalBg2, t.atoms.text]}>
              <Trans>1. Be kind and respectful.</Trans>
            </Text>
            <Text style={[styles.abilityItem, t.atoms.modalBg2, t.atoms.text]}>
              <Trans>2. Keep spills on topic.</Trans>
            </Text>
            <Text style={[styles.abilityItem, t.atoms.modalBg2, t.atoms.text]}>
              <Trans>3. Explore and share.</Trans>
            </Text>
          </View>
          <Button
            style={{marginTop: 'auto', marginBottom: 10}}
            label="circle detail"
            color="primary"
            variant="solid"
            size="large"
            onPress={onApply}>
            <ButtonText>
              <Trans>Agree and join</Trans>
            </ButtonText>
            {loading && <ButtonIcon icon={Loader} size="sm" />}
          </Button>
        </View>
      </View>
      <Dialog.Outer control={basic}>
        <Dialog.Inner label="test" style={{minHeight: 360}}>
          <View
            style={[a.flex_row, a.align_center, a.justify_between, a.pb_3xl]}>
            <Text>Our Rules</Text>
          </View>
        </Dialog.Inner>
      </Dialog.Outer>
    </>
  )
}

const styles = StyleSheet.create({
  abilityItem: {
    padding: 10,
    fontSize: 14,
    borderRadius: 16,
    borderBottomLeftRadius: 0,
  },
})
