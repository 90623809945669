/* eslint-disable react-native-a11y/has-valid-accessibility-descriptors */
import React, {useState} from 'react'
import {Image, TextInput, View} from 'react-native'
import {StyleSheet, Text} from 'react-native'
import {msg, Trans} from '@lingui/macro'
import {useLingui} from '@lingui/react'
import {useQueryClient} from '@tanstack/react-query'

import {useWebMediaQueries} from '#/lib/hooks/useWebMediaQueries'
import {capitalize} from '#/lib/strings/capitalize'
import {useModalControls} from '#/state/modals'
import {atoms as a, useTheme} from '#/alf'
import {Button, ButtonIcon} from '#/components/Button'
import * as TextField from '#/components/forms/TextField'
import {Eye_Stroke2_Corner0_Rounded as Eye} from '#/components/icons/Eye'
import {EyeSlash_Stroke2_Corner0_Rounded as EyeSlash} from '#/components/icons/EyeSlash'
import {Loader} from '#/components/Loader'
import {apiHash, apiId} from './config'
import {telegramUserBaseInfo, useTelegramClient, useTelegramLink} from './hooks'
import {userAuthParamCallback} from './util'

const passwordImg = require('../../../../../assets/telegram/password.png')

interface IProps {
  loginSuccess: () => void
}
export function LoginPassWd({loginSuccess}: IProps) {
  const t = useTheme()
  const {_} = useLingui()
  const {isDesktop} = useWebMediaQueries()
  const {closeModal} = useModalControls()
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState<string>()
  const [password, setPassword] = React.useState('')
  const [showPwd, setShowPwd] = React.useState(false)
  const {client, resetClient} = useTelegramClient()
  const {handleBind} = useTelegramLink()
  const queryClient = useQueryClient()

  const onConfirm = async () => {
    if (password && client) {
      setLoading(true)
      console.log('login with password success:', password)

      try {
        await client?.connect()
        await client?.signInWithPassword(
          {apiId, apiHash},
          {
            password: userAuthParamCallback(password),
            onError: async (err: any) => {
              setLoading(false)
              setError(err.errorMessage)
              return true
            },
          },
        )

        console.log('login with password success', client?.session.save())
        resetClient(client?.session.save()!)
        await handleBind()
        queryClient.invalidateQueries({queryKey: telegramUserBaseInfo})
        setLoading(false)
        loginSuccess()
      } catch (err: any) {
        setLoading(false)
      }
    }
  }

  return (
    <View style={[a.flex_1, a.py_5xl]}>
      <View>
        <View style={[a.justify_center, a.align_center]}>
          <Image
            source={passwordImg}
            style={[{width: 91, height: 91}]}
            accessibilityIgnoresInvertColors
          />
        </View>
        <Text style={[styles.title, t.atoms.text, a.mt_4xl]}>
          <Trans>Enter Password</Trans>
        </Text>
        <Text style={[a.mb_md, a.text_center, t.atoms.text_sub]}>
          <Trans>
            You’ve enabled Two-Step Verification. Add an extra layer of
            protection with an additional password.
          </Trans>
        </Text>
        <View style={a.my_lg}>
          <Text style={[a.mb_sm, error ? t.atoms.text_danger : t.atoms.text]}>
            {error ? (
              <Trans>{capitalize(error, '_', ' ')}</Trans>
            ) : (
              <Trans>Password</Trans>
            )}
          </Text>
          <View
            style={[
              styles.inputContainer,
              {height: 44},
              a.relative,
              a.p_xs,
              a.rounded_sm,
              a.pl_sm,
              a.pr_md,
              a.border,
              error
                ? {borderColor: t.atoms.text_danger.color}
                : t.atoms.input_border,
              t.atoms.input_bg,
              a.py_md,
              a.px_sm,
              a.rounded_sm,
            ]}>
            <TextInput
              style={[a.flex_1, t.atoms.text]}
              secureTextEntry={!showPwd}
              value={password}
              placeholder={_(msg`Enter your password`)}
              placeholderTextColor={t.atoms.text_sub.color}
              onChangeText={val => {
                setPassword(val)
                setError('')
              }}
            />
            <Button label={'eye'} onPress={() => setShowPwd(s => !s)}>
              <TextField.Icon icon={showPwd ? EyeSlash : Eye} />
            </Button>
          </View>
        </View>
      </View>

      <View
        style={[{gap: 24, marginTop: 16}, isDesktop ? a.flex_row_reverse : {}]}>
        <Button
          size="large"
          variant="solid"
          disabled={loading}
          label="Confirm"
          style={[a.flex_1, a.rounded_full, {backgroundColor: '#3390ED'}]}
          onPress={onConfirm}>
          <Text style={[a.font_bold, a.text_md, {color: '#ffffff'}]}>
            <Trans>Confirm</Trans>
          </Text>
          {loading && <ButtonIcon size="md" icon={Loader} />}
        </Button>
        <Button
          style={[t.atoms.bg_btn_default]}
          onPress={closeModal}
          label="Cancel"
          variant="solid"
          size="large">
          <Text style={[a.font_bold, t.atoms.text_sub, a.text_md]}>
            <Trans>Cancel</Trans>
          </Text>
        </Button>
      </View>
    </View>
  )
}

const styles = StyleSheet.create({
  page: {
    width: '100%',
    height: '100%',
    padding: 10,
  },
  title: {
    fontSize: 19,
    textAlign: 'center',
    fontWeight: 'bold',
    marginBottom: 16,
  },
  inputContainer: {
    borderWidth: 1,
    flexDirection: 'row',
    alignItems: 'center',
  },
})
