import React, {useCallback, useState} from 'react'
import {
  ActivityIndicator,
  KeyboardAvoidingView,
  ScrollView,
  StyleSheet,
  TextInput,
  TouchableOpacity,
  TouchableWithoutFeedback,
  View,
} from 'react-native'
import {Image as RNImage} from 'react-native-image-crop-picker'
import Animated, {FadeOut} from 'react-native-reanimated'
import {LinearGradient} from 'expo-linear-gradient'
import {AppBskyActorDefs} from '@atproto/api'
import {msg, Trans} from '@lingui/macro'
import {useLingui} from '@lingui/react'

import {useWebMediaQueries} from '#/lib/hooks/useWebMediaQueries'
import {logger} from '#/logger'
import {useModalControls} from '#/state/modals'
import {useProfileUpdateMutation} from '#/state/queries/profile'
import {usePostPreState} from '#/state/shell/post-pre-data'
import {useAnalytics} from 'lib/analytics/analytics'
import {MAX_DESCRIPTION, MAX_DISPLAY_NAME} from 'lib/constants'
import {usePalette} from 'lib/hooks/usePalette'
import {compressIfNeeded} from 'lib/media/manip'
import {cleanError} from 'lib/strings/errors'
import {enforceLen} from 'lib/strings/helpers'
import {colors, s} from 'lib/styles'
// import {useTheme} from 'lib/ThemeContext'
import {isWeb} from 'platform/detection'
import {useTheme} from '#/alf'
import {ErrorMessage} from '../util/error/ErrorMessage'
import {Text} from '../util/text/Text'
import * as Toast from '../util/Toast'
import {
  EditableUserAvatar,
  // UserAvatar
} from '../util/UserAvatar'
import {UserBanner} from '../util/UserBanner'
// import { EditIcon } from '#/view/icons/ProfileIcons'

const AnimatedTouchableOpacity =
  Animated.createAnimatedComponent(TouchableOpacity)

export const snapPoints = ['fullscreen']

export function Component({
  profile,
  onUpdate,
}: {
  profile: AppBskyActorDefs.ProfileViewDetailed
  onUpdate?: () => void
}) {
  const pal = usePalette('default')
  const t = useTheme()
  const {track} = useAnalytics()
  const {_} = useLingui()
  const {closeModal} = useModalControls()
  const {isDesktop} = useWebMediaQueries()
  const updateMutation = useProfileUpdateMutation()
  const {updatePostMaskAccounts} = usePostPreState()
  const [imageError, setImageError] = useState<string>('')
  const [displayName, setDisplayName] = useState<string>(
    profile.displayName || '',
  )
  const [description, setDescription] = useState<string>(
    profile.description || '',
  )
  const [userBanner, setUserBanner] = useState<string | undefined | null>(
    profile.banner,
  )
  const [userAvatar, setUserAvatar] = useState<string | undefined | null>(
    profile.avatar,
  )
  const [newUserBanner, setNewUserBanner] = useState<
    RNImage | undefined | null
  >()
  const [newUserAvatar, setNewUserAvatar] = useState<
    RNImage | undefined | null
  >()
  const onPressCancel = () => {
    closeModal()
  }
  const onSelectNewAvatar = useCallback(
    async (img: RNImage | null) => {
      setImageError('')
      if (img === null) {
        setNewUserAvatar(null)
        setUserAvatar(null)
        return
      }
      track('EditProfile:AvatarSelected')
      try {
        const finalImg = await compressIfNeeded(img, 1000000)
        setNewUserAvatar(finalImg)
        setUserAvatar(finalImg.path)
      } catch (e: any) {
        setImageError(cleanError(e))
      }
    },
    [track, setNewUserAvatar, setUserAvatar, setImageError],
  )

  const onSelectNewBanner = useCallback(
    async (img: RNImage | null) => {
      setImageError('')
      if (!img) {
        setNewUserBanner(null)
        setUserBanner(null)
        return
      }
      track('EditProfile:BannerSelected')
      try {
        const finalImg = await compressIfNeeded(img, 1000000)
        setNewUserBanner(finalImg)
        setUserBanner(finalImg.path)
      } catch (e: any) {
        setImageError(cleanError(e))
      }
    },
    [track, setNewUserBanner, setUserBanner, setImageError],
  )

  const onPressSave = useCallback(async () => {
    track('EditProfile:Save')
    setImageError('')
    try {
      await updateMutation.mutateAsync({
        profile,
        updates: {
          displayName,
          description,
        },
        newUserAvatar,
        newUserBanner,
      })
      Toast.show(_(msg`Profile updated`))
      onUpdate?.()
      updatePostMaskAccounts({
        did: profile.did,
        handle: profile.handle,
        name: displayName,
        avatar: userAvatar ?? undefined,
      })
      closeModal()
    } catch (e: any) {
      logger.error('Failed to update user profile', {message: String(e)})
    }
  }, [
    track,
    updateMutation,
    profile,
    onUpdate,
    closeModal,
    displayName,
    description,
    newUserAvatar,
    newUserBanner,
    setImageError,
    _,
    updatePostMaskAccounts,
    userAvatar,
  ])

  return (
    <KeyboardAvoidingView style={[s.flex1]} behavior="height">
      <ScrollView testID="editProfileModal">
        <View style={styles.header}>
          {!isWeb && (
            <>
              <TouchableWithoutFeedback
                accessibilityRole="button"
                onPress={onPressCancel}>
                <Text style={[styles.handleText, t.atoms.text, {left: 10}]}>
                  <Trans>Cancel</Trans>
                </Text>
              </TouchableWithoutFeedback>

              {!updateMutation.isPending ? (
                <TouchableWithoutFeedback
                  accessibilityRole="button"
                  onPress={onPressSave}>
                  <Text style={[styles.handleText, t.atoms.text, {right: 10}]}>
                    <Trans>Done</Trans>
                  </Text>
                </TouchableWithoutFeedback>
              ) : (
                <ActivityIndicator style={[styles.handleText, {right: 30}]} />
              )}
            </>
          )}
          <Text style={[styles.headerText, t.atoms.text, {fontWeight: 'bold'}]}>
            <Trans>Edit Profile</Trans>
          </Text>
        </View>
        <View style={styles.photos}>
          <UserBanner
            banner={userBanner}
            onSelectNewBanner={onSelectNewBanner}
          />
          <View style={[styles.avi, {borderColor: pal.colors.background}]}>
            <EditableUserAvatar
              size={80}
              avatar={userAvatar}
              onSelectNewAvatar={onSelectNewAvatar}
            />
          </View>
        </View>
        {updateMutation.isError && (
          <View style={styles.errorContainer}>
            <ErrorMessage message={cleanError(updateMutation.error)} />
          </View>
        )}
        {imageError !== '' && (
          <View style={styles.errorContainer}>
            <ErrorMessage message={imageError} />
          </View>
        )}
        <View style={styles.form}>
          <View>
            <Text
              style={[
                styles.label,
                // pal.text
              ]}>
              <Trans>Display Name</Trans>
            </Text>
            <TextInput
              testID="editProfileDisplayNameInput"
              style={[
                styles.textInput,
                pal.border,
                pal.text,
                t.atoms.input_bg,
                t.atoms.input_border,
              ]}
              placeholder={_(msg`e.g. Alice Roberts`)}
              placeholderTextColor={colors.gray4}
              value={displayName}
              onChangeText={v =>
                setDisplayName(enforceLen(v, MAX_DISPLAY_NAME))
              }
              accessible={true}
              accessibilityLabel={_(msg`Display name`)}
              accessibilityHint={_(msg`Edit your display name`)}
            />
          </View>
          <View style={s.pb10}>
            <Text
              style={[
                styles.label,
                // pal.text
              ]}>
              <Trans>Bio</Trans>
            </Text>
            <TextInput
              testID="editProfileDescriptionInput"
              style={[
                styles.textArea,
                pal.border,
                pal.text,
                t.atoms.input_bg,
                t.atoms.input_border,
              ]}
              placeholder={_(msg`e.g. Artist, dog-lover, and avid reader.`)}
              placeholderTextColor={colors.gray4}
              // keyboardAppearance={theme.colorScheme}
              multiline
              value={description}
              onChangeText={v => setDescription(enforceLen(v, MAX_DESCRIPTION))}
              accessible={true}
              accessibilityLabel={_(msg`Description`)}
              accessibilityHint={_(msg`Edit your profile description`)}
            />
          </View>
          {isWeb && (
            <View
              style={[
                isDesktop ? styles.actionContainer : {},
                {gap: 12, marginTop: 14},
              ]}>
              {updateMutation.isPending ? (
                <View
                  style={[
                    styles.btn,
                    {flex: 1, backgroundColor: colors.gray2},
                  ]}>
                  <ActivityIndicator color={t.palette.black} />
                </View>
              ) : (
                <TouchableOpacity
                  testID="editProfileSaveBtn"
                  style={[{flex: 1}]}
                  onPress={onPressSave}
                  accessibilityRole="button"
                  accessibilityLabel={_(msg`Save`)}
                  accessibilityHint={_(msg`Saves any changes to your profile`)}>
                  <LinearGradient
                    colors={[t.palette.primary, t.palette.primary]}
                    start={{x: 0, y: 0}}
                    end={{x: 1, y: 1}}
                    style={[styles.btn]}>
                    <Text style={[s.fw500]}>
                      <Trans>Save Changes</Trans>
                    </Text>
                  </LinearGradient>
                </TouchableOpacity>
              )}
              {!updateMutation.isPending && (
                <AnimatedTouchableOpacity
                  exiting={!isWeb ? FadeOut : undefined}
                  testID="editProfileCancelBtn"
                  style={[{flex: 1}]}
                  onPress={onPressCancel}
                  accessibilityRole="button"
                  accessibilityLabel={_(msg`Cancel profile editing`)}
                  accessibilityHint=""
                  onAccessibilityEscape={onPressCancel}>
                  <View style={[styles.btn, {backgroundColor: '#F1F3F5'}]}>
                    <Text style={[s.fw500, s.black]}>
                      <Trans>Cancel</Trans>
                    </Text>
                  </View>
                </AnimatedTouchableOpacity>
              )}
            </View>
          )}
        </View>
      </ScrollView>
    </KeyboardAvoidingView>
  )
}

const styles = StyleSheet.create({
  header: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    paddingHorizontal: 16,
    paddingVertical: 10,
    marginTop: -10,
    position: 'relative',
  },
  handleText: {
    fontSize: 16,
    fontWeight: '500',
    position: 'absolute',
    top: 13,
  },
  headerText: {
    fontSize: 18,
    fontWeight: '500',
    // transform: [{translateY: -2}],
  },
  title: {
    textAlign: 'center',
    fontWeight: 'bold',
    fontSize: 24,
    marginBottom: 18,
  },
  label: {
    fontWeight: 'bold',
    paddingHorizontal: 4,
    paddingBottom: 4,
    marginTop: 10,
    color: '#666666',
  },
  form: {
    paddingHorizontal: 14,
  },
  textInput: {
    borderWidth: 1,
    borderRadius: 12,
    paddingHorizontal: 14,
    paddingVertical: 10,
    fontSize: 16,
    height: 44,
  },
  textArea: {
    borderWidth: 1,
    borderRadius: 12,
    paddingHorizontal: 12,
    paddingTop: 10,
    fontSize: 16,
    height: 120,
    textAlignVertical: 'top',
  },
  btn: {
    flex: 1,
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: 32,
    paddingVertical: 13,
    fontWeight: '500',
  },
  avi: {
    position: 'absolute',
    top: 110,
    left: 24,
    width: 84,
    height: 84,
    borderWidth: 2,
    borderRadius: 42,
  },
  photos: {
    marginBottom: 36,
    marginHorizontal: -14,
  },
  errorContainer: {marginTop: 20},
  actionContainer: {
    display: 'flex',
    flexDirection: 'row-reverse',
    alignContent: 'center',
    alignItems: 'center',
    gap: 12,
  },
})
