import React from 'react'
import {AppBskyActorDefs as ActorDefs} from '@atproto/api'
import {msg} from '@lingui/macro'
import {useLingui} from '@lingui/react'

import {useProfileFollowersQuery} from '#/state/queries/profile-followers'
import {useSession} from '#/state/session'
import {atoms as a} from '#/alf'
import {ScrollView} from '../../modals/util'
import {EmptyBox} from '../../util/EmptyBox'
import {ItemProps} from '.'
import {Actor} from './Actor'

export const Followers = ({onPress, selecteds}: ItemProps) => {
  const {_} = useLingui()
  const {currentAccount} = useSession()
  // const layout = useWindowDimensions()
  const {
    data,
    // isLoading: isFollowersLoading,
    // isFetchingNextPage,
    // hasNextPage,
    // fetchNextPage,
    // error,
    // refetch,
  } = useProfileFollowersQuery(currentAccount?.did)

  const followers = React.useMemo(() => {
    if (data?.pages) {
      return data.pages.flatMap(page => page.followers)
    }
    return []
  }, [data])

  const renderItem = ({item}: {item: ActorDefs.ProfileViewBasic}) => {
    return (
      <Actor
        actor={item}
        onPress={onPress}
        style={[a.mt_md, a.justify_between]}
        isSelected={
          selecteds && selecteds?.findIndex(o => o?.did === item?.did) >= 0
        }
      />
    )
  }

  return (
    <ScrollView style={[a.mt_md, {height: 300}]}>
      <>
        {followers.length ? (
          followers.map(item => renderItem({item}))
        ) : (
          <EmptyBox
            icon={''}
            message={_(msg`No content available at the moment`)}
          />
        )}
      </>
    </ScrollView>
  )
}
