import React from 'react'
import {View} from 'react-native'
import {msg, Trans} from '@lingui/macro'
import {useLingui} from '@lingui/react'
import {useQueryClient} from '@tanstack/react-query'

import {EmptyBox} from '#/view/com/util/EmptyBox'
import {ViewHeader} from '#/view/com/util/ViewHeader'
import {atoms as a} from '#/alf'
import {Button, ButtonText} from '#/components/Button'
import {Loader} from '#/components/Loader'
import CircleRequestItem from './CircleRequestItem'
import {Circle, useCircleRequestList} from './hooks'

export default function CircleRequestList() {
  const {_} = useLingui()
  const queryClient = useQueryClient()
  const {data: requestList, isFetching, error} = useCircleRequestList()

  let content
  if (isFetching) {
    content = (
      <View style={[a.align_center, a.justify_center, a.p_5xl]}>
        <Loader size="lg" />
      </View>
    )
  } else if (error) {
    content = (
      <View style={[a.gap_lg]}>
        <EmptyBox icon="heart" message="has no request yet." />
        <Button
          style={{marginTop: 'auto', marginBottom: 10}}
          label="circle detail"
          color="primary"
          variant="solid"
          size="medium"
          onPress={() =>
            queryClient.invalidateQueries({queryKey: Circle.Request})
          }>
          <ButtonText>
            <Trans>Try again</Trans>
          </ButtonText>
        </Button>
      </View>
    )
  } else if (requestList?.length) {
    content = requestList.map(item => (
      <CircleRequestItem key={item.approveId} {...item} />
    ))
  } else {
    content = <EmptyBox icon="tea" message="has no request yet." />
  }

  return (
    <View style={[a.flex_1, {paddingBottom: 90}]}>
      <ViewHeader title={_(msg`Circle Request`)} />
      <View style={[a.px_xl, a.flex_1, a.py_lg]}>{content}</View>
    </View>
  )
}
