import React from 'react'

import {useAgent} from '#/state/session'

const TwitterStore = {
  init: true,
  bindTwitter: true,
  twitterUserId: '',
  twitterUserName: '',
}

export function useTwitter() {
  const agent = useAgent()

  const bindTwitter = React.useCallback(
    async (code: string) => {
      try {
        const res = await agent.com.atproto.server.bindTwitter({code})
        return res.data
      } catch (err) {
        throw err
      }
    },
    [agent],
  )

  const getBindTwitter = React.useCallback(async () => {
    if (TwitterStore.init) {
      return TwitterStore
    } else {
      try {
        const res = await agent.com.atproto.server.getBindTwitter()
        if (res.data) {
          TwitterStore.init = true
          Object.assign(TwitterStore, res.data)
        }
      } catch (err) {
        throw err
      } finally {
        return TwitterStore
      }
    }
  }, [agent])

  const setBindTwitter = (data: any) => {
    Object.assign(TwitterStore, data)
  }

  return {bindTwitter, getBindTwitter, setBindTwitter}
}
