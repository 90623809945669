import React, {useCallback, useEffect, useMemo} from 'react'
import {Image, StyleSheet, Text, TouchableOpacity, View} from 'react-native'

import {useAgent} from '#/state/session'
import {IOnBet} from '#/state/transaction/bet'
import {IOnStake} from '#/state/transaction/stake'
import {useGuarantor} from '#/view/com/post-thread/PostGuarantor'
import {RightIcon, StartIcon} from '#/view/icons/ModalIcons'
import {useTheme} from '#/alf'
import {atoms as a} from '#/alf'
import {Button} from '#/components/Button'
import {BetModalProps} from '../../modals/BetToEarn'
import {StakeDonateModalType} from '../../modals/StakeDonate'
import {Actor} from '../../post-thread/PostGuarantor/Actor'
import {usePostStepStore} from '../../util/sdlStore/PostStepStore'
import {EllipsisBox} from './EllipsisContainer'

interface ICredibility {
  amount: string | undefined
  showStakeModal: (param?: StakeDonateModalType) => void
  onStake: (stakeProps: IOnStake) => Promise<void>
  showBetModal: (param?: BetModalProps) => void
  betAmount: number | undefined
  onCreateBet: (betProps: IOnBet) => Promise<void>
}

export function Credibility({
  showStakeModal,
  showBetModal,
  amount,
  onStake,
  betAmount,
  onCreateBet,
}: ICredibility) {
  const {atoms, name} = useTheme()

  const hasBorder = useMemo(() => {
    if (name === 'light') {
      return true
    }
  }, [name])

  const {control, dialog, actors} = useGuarantor()
  const {
    stepStore,
    startStake,
    startBet,
    finishAllStep,
    nextStep,
    setGuarantorParams,
    startGuarantor,
    finishGuarantor,
  } = usePostStepStore()

  const agent = useAgent()

  useEffect(() => {
    if (actors.length > 0) {
      setGuarantorParams(actors)
    }
  }, [actors, setGuarantorParams])

  const onPressStake = useCallback(() => {
    showStakeModal('create-post-stake')
  }, [showStakeModal])

  const executeOnStake = () => {
    if (stepStore.stakeParams) {
      startStake()
      onStake(stepStore.stakeParams)
    } else {
      nextStep()
    }
  }

  const guarantorReq = async () => {
    const params = {
      subject: {
        uri: stepStore.postInfo.uri,
        cid: stepStore.postInfo.cid,
      },
      status: 0,
      createdAt: new Date().toISOString(),
      $type: 'app.bsky.graph.guarantee',
    }
    const records =
      stepStore.guarantorParams?.map(item => {
        return {
          ...params,
          guarantor: item.did,
        }
      }) || []
    await agent.com.atproto.repo.createRecords({
      collection: 'app.bsky.graph.guarantee',
      repo: stepStore.postInfo.did,
      records: records,
    })
    finishGuarantor()
  }

  const executeGuarantor = () => {
    if (stepStore.guarantorParams) {
      startGuarantor()
      guarantorReq()
    } else {
      nextStep()
    }
  }

  const executeOnBet = () => {
    if (stepStore.betParams) {
      startBet()
      onCreateBet(stepStore.betParams)
    } else {
      nextStep()
    }
  }

  const onPressBet = useCallback(() => {
    showBetModal()
  }, [showBetModal])

  useEffect(() => {
    switch (stepStore.step) {
      case 'guarantor':
        executeGuarantor()
        break
      case 'stake':
        executeOnStake()
        break
      case 'bet':
        executeOnBet()
        break
      case 'finish':
        finishAllStep()
        break
      default:
        break
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [stepStore.step])

  const onSelectGuarantor = () => {
    control.open()
  }

  return (
    <View style={[styles.page, atoms.modalBg2]}>
      <Text style={[atoms.text, styles.topText]}>More Options</Text>
      <TouchableOpacity
        accessibilityRole="button"
        style={{}}
        onPress={onPressStake}>
        <View
          style={[
            styles.item,
            atoms.modalItemBg,
            hasBorder && {borderWidth: 1},
          ]}>
          <Image
            accessibilityIgnoresInvertColors={true}
            source={require('../../../../../assets/imgs/credibility1.png')}
            style={styles.avatar}
          />

          <View>
            <View style={styles.top}>
              <Text style={[atoms.text, styles.topText]}>
                Stake to Validate
              </Text>
              <StartIcon />
              <StartIcon />
              {false && <RightIcon />}
            </View>
            {amount ? (
              <Text style={[a.text_lg, atoms.text, {fontWeight: 'bold'}]}>
                {parseFloat(amount).toLocaleString()}
                {'  USDC'}
              </Text>
            ) : (
              <Text style={[atoms.text, styles.bottomText]}>
                Show the Confidence or Promote the Post
              </Text>
            )}
          </View>
        </View>
      </TouchableOpacity>
      <Button
        label="Guarantor button"
        accessibilityRole="button"
        style={{}}
        onPress={onSelectGuarantor}>
        <View
          style={[
            styles.item,
            a.flex_1,
            atoms.modalItemBg,
            hasBorder && {borderWidth: 1},
          ]}>
          <Image
            accessibilityIgnoresInvertColors={true}
            source={require('../../../../../assets/imgs/credibility2.png')}
            style={styles.avatar}
          />

          <View style={[a.flex_1]}>
            <View style={styles.top}>
              <Text style={[atoms.text, styles.topText]}>Guarantors</Text>
              <StartIcon />
              <StartIcon />
              <StartIcon />
              {/* <RightIcon /> */}
            </View>
            {actors && actors.length > 0 ? (
              <>
                <EllipsisBox
                  array={actors}
                  keyName="did"
                  height={30}
                  renderItem={actor => (
                    <Actor
                      selected
                      type="small"
                      onPress={onSelectGuarantor}
                      size={18}
                      key={actor.did}
                      actor={actor}
                      hasBorder
                    />
                  )}
                />
              </>
            ) : (
              <Text style={[atoms.text, styles.bottomText]}>
                Invite someone who can support you
              </Text>
            )}
          </View>
        </View>
      </Button>

      {false && (
        <TouchableOpacity
          accessibilityRole="button"
          style={{}}
          onPress={onPressBet}>
          <View
            style={[
              styles.item,
              atoms.modalItemBg,
              hasBorder && {borderWidth: 1},
            ]}>
            <Image
              accessibilityIgnoresInvertColors={true}
              source={require('../../../../../assets/imgs/credibility3.png')}
              style={styles.avatar}
            />
            <View>
              <View style={styles.top}>
                <Text style={[atoms.text, styles.topText]}>Bet to earn</Text>
                <StartIcon />
                <StartIcon />
                <StartIcon />
                <StartIcon />
                <StartIcon />
                {/* <RightIcon /> */}
              </View>
              {betAmount ? (
                <Text style={[a.text_lg, atoms.text, {fontWeight: 'bold'}]}>
                  {betAmount?.toLocaleString()}
                  {'  USDC'}
                </Text>
              ) : (
                <Text style={[atoms.text, styles.bottomText]}>
                  Show your swagger
                </Text>
              )}
            </View>
          </View>
        </TouchableOpacity>
      )}
      {dialog}
    </View>
  )
}
const styles = StyleSheet.create({
  page: {
    width: '100%',
    paddingVertical: 10,
    paddingHorizontal: 12,
  },
  mainIcon: {
    marginHorizontal: 12,
  },
  item: {
    marginTop: 8,
    flexDirection: 'row',
    alignItems: 'center',
    height: 60,
    borderColor: 'rgba(151, 151, 151, 0.2)',
    borderRadius: 8,
  },
  topText: {
    fontWeight: 'bold',
    fontSize: 12,
    marginRight: 4,
  },
  bottomText: {
    fontSize: 12,
  },
  top: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  avatar: {
    width: 42,
    height: 42,
    marginHorizontal: 12,
  },
})
