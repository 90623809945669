import React from 'react'
import {StyleProp, StyleSheet, View, ViewStyle} from 'react-native'
import {
  AppBskyEmbedExternal,
  AppBskyEmbedImages,
  AppBskyEmbedRecordWithMedia,
  AppBskyFeedPost,
  ModerationDecision,
  RichText as RichTextAPI,
} from '@atproto/api'
import {AtUri} from '@atproto/api'
import {useQueryClient} from '@tanstack/react-query'

import {usePalette} from 'lib/hooks/usePalette'
import {makeProfileLink} from 'lib/routes/links'
import {precacheProfile} from 'state/queries/profile'
import {ComposerOptsQuote} from 'state/shell/composer'
import {formatHandler} from '#/screens/Onboarding/util'
import {atoms as a, useTheme} from '#/alf'
import {ContentHider} from '../../../../components/moderation/ContentHider'
import {PostAlerts} from '../../../../components/moderation/PostAlerts'
import {Link} from '../../util/Link'
import {Text} from '../../util/text/Text'
import {BigFigurePostContent} from './BigFigureCard'
import {BigFigurePostMeta} from './BigFigurePostMeta'

export function BigFigureQuoteEmbed({
  quote,
  moderation,
  onOpen,
  style,
  allowNestedQuotes,
  record,
}: {
  quote: ComposerOptsQuote
  moderation?: ModerationDecision
  onOpen?: () => void
  style?: StyleProp<ViewStyle>
  allowNestedQuotes?: boolean
  record: AppBskyFeedPost.Record
}) {
  const t = useTheme()
  const queryClient = useQueryClient()
  const pal = usePalette('default')
  const itemUrip = new AtUri(quote.uri)
  const itemHref = makeProfileLink(quote.author, 'post', itemUrip.rkey)
  const itemTitle = `Post by ${formatHandler(quote.author.handle)}`

  const richText = React.useMemo(
    () =>
      quote.text.trim()
        ? new RichTextAPI({text: quote.text, facets: quote.facets})
        : undefined,
    [quote.text, quote.facets],
  )

  const embed = React.useMemo(() => {
    const e = quote.embeds?.[0]

    if (allowNestedQuotes) {
      return e
    } else {
      if (AppBskyEmbedImages.isView(e) || AppBskyEmbedExternal.isView(e)) {
        return e
      } else if (
        AppBskyEmbedRecordWithMedia.isView(e) &&
        (AppBskyEmbedImages.isView(e.media) ||
          AppBskyEmbedExternal.isView(e.media))
      ) {
        return e.media
      }
    }
  }, [quote.embeds, allowNestedQuotes])

  const onBeforePress = React.useCallback(() => {
    precacheProfile(queryClient, quote.author)
    onOpen?.()
  }, [queryClient, quote.author, onOpen])

  return (
    <ContentHider
      modui={moderation?.ui('contentList')}
      style={[styles.container, pal.border, style, t.atoms.bg_quote_post]}
      childContainerStyle={[a.pt_sm]}>
      <Link
        hoverStyle={{borderColor: pal.colors.borderLinkHover}}
        href={itemHref}
        title={itemTitle}
        onBeforePress={onBeforePress}>
        <View pointerEvents="none">
          <BigFigurePostMeta
            author={quote.author}
            moderation={moderation}
            showAvatar
            authorHasWarning={false}
          />
        </View>

        {moderation ? (
          <PostAlerts modui={moderation.ui('contentView')} style={[a.py_xs]} />
        ) : null}
        {/* Title */}
        {record.title && (
          <Text
            style={[
              a.mt_lg,
              t.atoms.text,
              styles.smallTitle,
              {backgroundColor: 'transparent'},
            ]}
            numberOfLines={2}>
            {record.title}
          </Text>
        )}
        <BigFigurePostContent
          moderation={moderation}
          richText={richText}
          postEmbed={embed}
          postAuthor={quote.author}
          // onOpenEmbed={onOpenEmbed}
          sizeMode={'small'}
        />
      </Link>
    </ContentHider>
  )
}

const styles = StyleSheet.create({
  container: {
    borderRadius: 30,
    padding: 12,
    borderWidth: StyleSheet.hairlineWidth,
  },
  smallTitle: {
    fontSize: 20,
    fontWeight: '900',
  },
})
