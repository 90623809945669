import React from 'react'
import {StyleProp, Text, View, ViewStyle} from 'react-native'
import {AppBskyActorDefs as ActorDefs} from '@atproto/api'
import {FontAwesomeIcon} from '@fortawesome/react-native-fontawesome'

import {UserAvatar} from '#/view/com/util/UserAvatar'
import {CloseIcon} from '#/view/icons/ModalIcons'
import {formatHandler} from '#/screens/Onboarding/util'
import {atoms as a, useTheme} from '#/alf'
import {Button} from '#/components/Button'

type Props = {
  type?: 'small' | 'default'
  actor: ActorDefs.ProfileView
  size?: number
  selected?: boolean
  showClose?: boolean
  hasBorder?: boolean
  onPress?: (actor: ActorDefs.ProfileViewBasic) => void
  onDelete?: (actor: ActorDefs.ProfileViewBasic) => void
  style?: StyleProp<ViewStyle>
  isSelected?: boolean
}
export function Actor({
  type = 'default',
  actor,
  selected,
  showClose,
  onPress,
  onDelete,
  hasBorder,
  size = 32,
  style,
  isSelected,
}: Props) {
  const t = useTheme()
  let styles = {
    button: {
      backgroundColor: 'transparent', //t.palette.white,
      padding: 4,
      borderRadius: a.rounded_full.borderRadius,
    },
    name: {
      fontSize: 14,
      color: t.atoms.text.color,
    },
    handle: {
      fontSize: 14,
    },
  }
  if (selected) {
    styles = {
      button: {
        backgroundColor: t.palette.primary_50,
        padding: 2,
        borderRadius: a.rounded_full.borderRadius,
      },
      name: {
        fontSize: 12,
        color: t.atoms.text.color,
      },
      handle: {
        fontSize: 10,
      },
    }
  }
  return (
    <Button
      style={[
        a.justify_start,
        hasBorder && {
          borderWidth: 1,
          borderRadius: 100,
          borderColor: t.palette.primary,
        },
        style,
      ]}
      key={actor.did}
      label="follower"
      onPress={() =>
        !selected || onPress ? onPress?.(actor) : onDelete?.(actor)
      }>
      <View
        style={[
          a.flex_row,
          a.align_center,
          a.justify_center,
          a.p_md,
          a.gap_xs,
          styles.button,
          {paddingLeft: 4, paddingRight: 8},
        ]}>
        <UserAvatar size={size} avatar={actor.avatar} />
        <View style={[a.flex_col]}>
          <View style={[]}>
            <Text style={[a.font_bold, styles.name]}>
              {actor.displayName || formatHandler(actor.handle)}
            </Text>
          </View>
          {type === 'default' && (
            <View style={[]}>
              <Text style={[a.text_xs, t.atoms.text_sub, styles.handle]}>
                @{formatHandler(actor.handle)}
              </Text>
            </View>
          )}
        </View>
        {showClose && <CloseIcon circleColor={t.palette.black} />}
      </View>
      {isSelected && (
        <FontAwesomeIcon
          icon={'check'}
          color={t.palette.green_11}
          style={[a.mr_lg]}
        />
      )}
    </Button>
  )
}
