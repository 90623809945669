import {Router} from 'lib/routes/router'

export const router = new Router({
  Home: '/',
  TelegramAuth: '/auth/telegram',
  TelegramBind: ['/bind/telegram', '/bind/telegram/:step'],
  Search: '/search',
  Feeds: '/feeds',
  Mask: '/mask',
  SavedMask: '/mask/create',
  MaskSetting: '/mask/setting/:handle',
  Notifications: '/notifications',
  NotificationsSettings: '/notifications/settings',
  Settings: '/settings',
  LanguageSettings: '/settings/language',
  Lists: '/lists',
  Moderation: '/moderation',
  ModerationModlists: '/moderation/modlists',
  ModerationMutedAccounts: '/moderation/muted-accounts',
  ModerationBlockedAccounts: '/moderation/blocked-accounts',
  Profile: ['/profile/:name', '/profile/:name/rss'],
  ProfileFollowers: '/profile/:name/followers',
  ProfileFollows: '/profile/:name/follows',
  ProfileKnownFollowers: '/profile/:name/known-followers',
  ProfileList: '/profile/:name/lists/:rkey',
  PostThread: '/profile/:name/post/:rkey',
  PostLikedBy: '/profile/:name/post/:rkey/liked-by',
  PostRepostedBy: '/profile/:name/post/:rkey/reposted-by',
  ProfileFeed: '/profile/:name/feed/:rkey',
  ProfileFeedLikedBy: '/profile/:name/feed/:rkey/liked-by',
  ProfileLabelerLikedBy: '/profile/:name/labeler/liked-by',
  Debug: '/sys/debug',
  DebugMod: '/sys/debug-mod',
  Log: '/sys/log',
  AppPasswords: '/settings/app-passwords',
  PreferencesFollowingFeed: '/settings/following-feed',
  PreferencesThreads: '/settings/threads',
  PreferencesExternalEmbeds: '/settings/external-embeds',
  AccessibilitySettings: '/settings/accessibility',
  AppearanceSettings: '/settings/appearance',
  SavedFeeds: '/settings/saved-feeds',
  Support: '/support',
  PrivacyPolicy: '/support/privacy',
  TermsOfService: '/support/tos',
  CommunityGuidelines: '/support/community-guidelines',
  CopyrightPolicy: '/support/copyright',
  Hashtag: '/hashtag/:tag',
  Messages: '/messages',
  MessagesSettings: '/messages/settings',
  MessagesConversation: '/messages/:conversation',
  Start: '/start/:name/:rkey',
  StarterPackEdit: '/starter-pack/edit/:rkey',
  StarterPack: '/starter-pack/:name/:rkey',
  StarterPackShort: '/starter-pack-short/:code',
  StarterPackWizard: '/starter-pack/create',
  BetList: '/bet/:did/:dataType/:rkey',
  LinkedWallet: '/link-wallet',
  BetScoreList: '/bet-score/:did/:type/:rkey',
  TransAssets: '/trans-assets',
  Authorize: '/authorize',
  Circle: '/circle',
  CircleUpdate: ['/circle/edit/:id'],
  CircleCreate: '/circle/create',
  CircleInvite: '/circle/invite/:id',
  CircleRequestList: '/circle/request',
  CircleApply: '/circle/apply/:id',
  CircleMembers: '/circle/members/:id',
  CircleNotifications: '/circle/notifications/:id',
  CircleDetail: '/circle/detail/:id',
  Connections: '/connections',
  FriendRequestList: '/friend/request',
  Friend: '/friend',
  AddFriend: '/friend/add',
  TagList: '/tag',
  Task: '/task',
  ActivityQRCodeScreen: '/activity/qrcode',
  AuditScreen: '/audit',
})
