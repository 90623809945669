import React, {memo, useCallback, useState} from 'react'
import {
  Pressable,
  type PressableStateCallbackType,
  type StyleProp,
  StyleSheet,
  View,
  type ViewStyle,
} from 'react-native'
import {
  AppBskyFeedDefs,
  AppBskyFeedPost,
  AtUri,
  RichText as RichTextAPI,
} from '@atproto/api'
import {plural} from '@lingui/macro'
import {ethers} from 'ethers'

import {POST_CTRL_HITSLOP} from '#/lib/constants'
import {useHaptics} from '#/lib/haptics'
import {Shadow} from '#/state/cache/types'
import {useFeedFeedbackContext} from '#/state/feed-feedback'
import {useModalControls} from '#/state/modals'
import {
  usePostLikeMutationQueue,
  usePostOpposeMutationQueue,
  usePostRepostMutationQueue,
} from '#/state/queries/post'
import {useStakeTransSummaryQuery} from '#/state/queries/transaction-bet'
import {useAgent, useRequireAuth, useSession} from '#/state/session'
import {useComposerControls} from '#/state/shell/composer'
import {
  ProgressGuideAction,
  useProgressGuideControls,
} from '#/state/shell/progress-guide'
import {
  BigFigureUpvoteIcon,
  BigGigureStakeIcon,
  CcComment,
  CcLike,
  CCUnLike,
} from '#/view/icons/FeedIcons'
import {formatHandler} from '#/screens/Onboarding/util'
import {atoms as a, useTheme} from '#/alf'
import {AutoTranslate} from '../../posts/TranslationWidget/TranslationWidget'
import {PostDropdownBtn} from '../forms/PostDropdownBtn'
import {formatCount, formatShortOnly} from '../numeric/format'
import {Text} from '../text/Text'
import {RepostButton} from './RepostButton'

let BigFigurePostCtrls = ({
  big,
  post,
  record,
  feedContext,
  richText,
  style,
  onPressReply,
  logContext,
  showRightBt,
  showAutoTranslate,
  isFromFeed,
  hideRely,
}: {
  big?: boolean
  post: Shadow<AppBskyFeedDefs.PostView>
  record: AppBskyFeedPost.Record
  richText: RichTextAPI
  showRightBt?: boolean
  feedContext?: string | undefined
  style?: StyleProp<ViewStyle>
  onPressReply: () => void
  logContext: 'FeedItem' | 'PostThreadItem' | 'Post'
  showAutoTranslate?: boolean
  isFromFeed?: boolean
  hideRely?: boolean
}): React.ReactNode => {
  const [oppose, setOppose] = useState({
    amount: post.opposeCount || 0,
    key: post.viewer?.oppose || '',
  })
  const agent = useAgent()
  const t = useTheme()
  const {currentAccount} = useSession()
  const {openComposer} = useComposerControls()
  const [queueLike, queueUnlike] = usePostLikeMutationQueue(post, logContext)
  const [queueOppose, queueUnoppose] = usePostOpposeMutationQueue(
    post,
    logContext,
  )
  const [queueRepost, queueUnrepost] = usePostRepostMutationQueue(
    post,
    logContext,
  )
  const requireAuth = useRequireAuth()
  const {sendInteraction} = useFeedFeedbackContext()
  const {captureAction} = useProgressGuideControls()
  const playHaptic = useHaptics()
  const {data: stakeSummary} = useStakeTransSummaryQuery(
    post.uri,
    false,
    // @ts-ignore
    post?.stakeCount ?? 0,
  )

  const onPressToggleLike = React.useCallback(async () => {
    try {
      if (!post.viewer?.like) {
        playHaptic()
        sendInteraction({
          item: post.uri,
          event: 'app.bsky.feed.defs#interactionLike',
          feedContext,
        })
        captureAction(ProgressGuideAction.Like)
        await queueLike()
        if (post.viewer?.oppose) {
          await queueUnoppose()
        }
      } else {
        await queueUnlike()
      }
    } catch (e: any) {
      if (e?.name !== 'AbortError') {
        throw e
      }
    }
  }, [
    playHaptic,
    post.uri,
    post.viewer?.like,
    queueLike,
    queueUnlike,
    sendInteraction,
    captureAction,
    feedContext,
    post.viewer?.oppose,
    queueUnoppose,
  ])

  const onPressToggleUnLike = React.useCallback(async () => {
    if (oppose.key) {
      setOppose({
        amount: 0,
        key: '',
      })
      agent.com.atproto.repo.deleteRecord({
        collection: 'app.bsky.feed.oppose',
        repo: currentAccount?.did || '',
        rkey: oppose.key.split('/').pop() || '',
      })
    } else {
      const temp = {
        collection: 'app.bsky.feed.oppose',
        repo: currentAccount?.did || '',
        record: {
          subject: {
            uri: post.uri,
            cid: post.cid,
          },
          createdAt: new Date().toISOString(),
          $type: 'app.bsky.feed.oppose',
        },
      }
      const res = await agent.com.atproto.repo.createRecord(temp)

      setOppose({
        amount: oppose.amount ? oppose.amount++ : 1,
        key: res.data.uri,
      })
    }
  }, [agent.com.atproto.repo, post, currentAccount?.did, setOppose, oppose])
  onPressToggleUnLike

  const {openModal} = useModalControls()

  const btnStyle = React.useCallback(
    ({pressed, hovered}: PressableStateCallbackType) => [
      a.gap_xs,
      a.rounded_full,
      a.flex_row,
      a.align_center,
      a.justify_center,
      {padding: 5},
      (pressed || hovered) && t.atoms.bg_contrast_25,
    ],
    [t.atoms.bg_contrast_25],
  )

  const totalStakeValue = stakeSummary?.totalStakeAmount
    ? Number(
        ethers.formatUnits(
          stakeSummary?.totalStakeAmount - stakeSummary?.totalWithdrawAmount,
          6,
        ),
      )
    : 0
  const onPressStake = useCallback(() => {
    requireAuth(() => {
      openModal({
        name: 'stake-donate',
        type: 'stake',
        rKey: new AtUri(post.uri).rkey,
        transPostAuthor: {
          did: post.author.did,
          avatar: post.author.avatar ?? '',
          displayName:
            post.author.displayName && post.author.displayName !== ''
              ? post.author.displayName
              : formatHandler(post.author.handle ?? ''),
        },
      })
    })
  }, [
    openModal,
    post?.author?.avatar,
    post?.author?.did,
    post?.author?.displayName,
    post?.author?.handle,
    post?.uri,
    requireAuth,
  ])

  const onPressToggleOppose = React.useCallback(async () => {
    try {
      if (!post.viewer?.oppose) {
        playHaptic()
        sendInteraction({
          item: post.uri,
          event: 'app.bsky.feed.defs#interactionOppose',
          feedContext,
        })
        captureAction(ProgressGuideAction.Oppose)
        await queueOppose()
        if (post.viewer?.like) {
          await queueUnlike()
        }
      } else {
        await queueUnoppose()
      }
    } catch (e: any) {
      if (e?.name !== 'AbortError') {
        throw e
      }
    }
  }, [
    playHaptic,
    post.uri,
    post.viewer?.oppose,
    queueOppose,
    queueUnoppose,
    sendInteraction,
    captureAction,
    feedContext,
    post.viewer?.like,
    queueUnlike,
  ])

  const onRepost = useCallback(async () => {
    try {
      if (!post.viewer?.repost) {
        sendInteraction({
          item: post.uri,
          event: 'app.bsky.feed.defs#interactionRepost',
          feedContext,
        })
        await queueRepost()
      } else {
        await queueUnrepost()
      }
    } catch (e: any) {
      if (e?.name !== 'AbortError') {
        throw e
      }
    }
  }, [
    post.uri,
    post.viewer?.repost,
    queueRepost,
    queueUnrepost,
    sendInteraction,
    feedContext,
  ])

  const onQuote = useCallback(() => {
    sendInteraction({
      item: post.uri,
      event: 'app.bsky.feed.defs#interactionQuote',
      feedContext,
    })
    openComposer({
      quote: {
        uri: post.uri,
        cid: post.cid,
        text: record.text,
        author: post.author,
        indexedAt: post.indexedAt,
      },
    })
  }, [
    openComposer,
    post.uri,
    post.cid,
    post.author,
    post.indexedAt,
    record.text,
    sendInteraction,
    feedContext,
  ])

  if (isFromFeed) {
    return (
      <View style={{flexDirection: 'row', flex: 1}}>
        <View
          style={[
            a.flex_row,
            // a.justify_between,
            a.align_center,
            style,
            {flex: 1},
          ]}>
          <View style={{flex: 1, alignItems: 'flex-start'}}>
            <Pressable
              testID="replyBtn"
              style={btnStyle}
              onPress={() => {
                if (!post.viewer?.replyDisabled) {
                  requireAuth(() => onPressReply())
                }
              }}
              accessibilityLabel={plural(post.replyCount || 0, {
                one: 'Reply (# reply)',
                other: 'Reply (# replies)',
              })}
              accessibilityHint=""
              hitSlop={POST_CTRL_HITSLOP}>
              <CcComment
                size={17}
                fixedColor={t.palette.gray_17}
                strokeWidth={1}
                strokeWidth2={1.5}
              />
              {typeof post.replyCount !== 'undefined' && post.replyCount > 0 ? (
                <Text
                  style={[
                    {color: t.palette.gray_17},
                    big ? a.text_md : {fontSize: 15},
                    a.user_select_none,
                  ]}>
                  {formatCount(post.replyCount)}
                </Text>
              ) : undefined}
            </Pressable>
          </View>

          <View style={{flex: 1, alignItems: 'flex-start'}}>
            <Pressable
              testID="likeBtn"
              style={btnStyle}
              onPress={() => requireAuth(() => onPressToggleLike())}
              accessibilityLabel={
                post.viewer?.like
                  ? plural(post.likeCount || 0, {
                      one: 'Unlike (# like)',
                      other: 'Unlike (# likes)',
                    })
                  : plural(post.likeCount || 0, {
                      one: 'Like (# like)',
                      other: 'Like (# likes)',
                    })
              }
              accessibilityHint=""
              hitSlop={POST_CTRL_HITSLOP}>
              {!!post.viewer?.like && !!currentAccount?.did ? (
                <BigFigureUpvoteIcon />
              ) : (
                <CcLike
                  active={false}
                  fixedColor={t.palette.gray_17}
                  size={17}
                  strokeWidth={1}
                />
              )}

              {typeof post.likeCount !== 'undefined' && post.likeCount > 0 ? (
                <Text
                  testID="likeCount"
                  style={[
                    [
                      big ? a.text_md : {fontSize: 15},
                      a.user_select_none,
                      post.viewer?.like && !!currentAccount?.did
                        ? [a.font_bold, t.atoms.text]
                        : {color: t.palette.gray_17},
                    ],
                  ]}>
                  {formatCount(post.likeCount)}
                </Text>
              ) : undefined}
            </Pressable>
          </View>

          <View style={[{flex: 2}]}>
            {totalStakeValue ? (
              <Pressable
                accessibilityRole="button"
                testID={`postStakeList-by-${post.uri}`}
                style={[a.flex_row, a.align_center, a.gap_xs]}
                onPress={onPressStake}>
                <BigGigureStakeIcon />
                <Text
                  style={[{fontSize: 15}, a.font_semibold, t.atoms.text_sub]}>
                  {`$${formatShortOnly(Number(totalStakeValue), 2)} Endorse`}
                </Text>
              </Pressable>
            ) : (
              ''
            )}
          </View>
        </View>
        <View style={[a.flex_row, a.align_center]}>
          {showAutoTranslate && <AutoTranslate style={{marginTop: 5}} />}
          <PostDropdownBtn
            testID="postDropdownBtn"
            post={post}
            postFeedContext={feedContext}
            record={record}
            richText={richText}
            // style={{padding: 5}}
            hitSlop={POST_CTRL_HITSLOP}
            timestamp={post.indexedAt}
          />
        </View>
      </View>
    )
  }

  return (
    <View
      style={{
        flexDirection: 'row',
        width: '100%',
        borderWidth: 0,
        marginBottom: -5,
      }}>
      <View
        style={[
          a.flex_row,
          a.justify_between,
          a.align_center,
          style,
          !showRightBt && {flex: 1, justifyContent: 'space-between'},
        ]}>
        {!hideRely && (
          <Pressable
            testID="replyBtn"
            style={styles.item}
            onPress={() => {
              if (!post.viewer?.replyDisabled) {
                requireAuth(() => onPressReply())
              }
            }}
            accessibilityLabel={plural(post.replyCount || 0, {
              one: 'Reply (# reply)',
              other: 'Reply (# replies)',
            })}
            accessibilityHint=""
            hitSlop={POST_CTRL_HITSLOP}>
            <CcComment fixedColor={t.palette.gray_17} size={big ? 24 : 17} />
            {typeof post.replyCount !== 'undefined' && post.replyCount > 0 ? (
              <Text
                style={[
                  styles.text2,
                  t.atoms.text,
                  big ? a.text_md : {fontSize: 15},
                  a.user_select_none,
                ]}>
                {formatCount(post.replyCount)}
              </Text>
            ) : undefined}
          </Pressable>
        )}

        <Pressable
          testID="likeBtn"
          style={styles.item}
          onPress={() => requireAuth(() => onPressToggleLike())}
          accessibilityLabel={
            post.viewer?.like
              ? plural(post.likeCount || 0, {
                  one: 'Unlike (# like)',
                  other: 'Unlike (# likes)',
                })
              : plural(post.likeCount || 0, {
                  one: 'Like (# like)',
                  other: 'Like (# likes)',
                })
          }
          accessibilityHint=""
          hitSlop={POST_CTRL_HITSLOP}>
          <CcLike
            fixedColor={
              !!post.viewer?.like && !!currentAccount?.did
                ? t.atoms.text.color
                : t.palette.gray_17
            }
            active={!!post.viewer?.like && !!currentAccount?.did}
            size={big ? 24 : 17}
            strokeWidth={1.5}
          />
          {typeof post.likeCount !== 'undefined' && post.likeCount > 0 ? (
            <Text
              testID="likeCount"
              style={[
                [
                  big ? a.text_md : {fontSize: 15},
                  a.user_select_none,
                  styles.text2,
                  post.viewer?.like ? t.atoms.text : t.atoms.text_sub,
                  post.viewer?.like && !!currentAccount?.did
                    ? {fontWeight: 'bold'}
                    : null,
                ],
              ]}>
              {formatCount(post.likeCount)}
            </Text>
          ) : undefined}
        </Pressable>

        <Pressable
          testID="opposeBtn"
          style={btnStyle}
          onPress={() => requireAuth(() => onPressToggleOppose())}
          accessibilityLabel={
            post.viewer?.oppose
              ? plural(post.opposeCount || 0, {
                  one: 'Unoppose (# oppose)',
                  other: 'Unoppose (# opposes)',
                })
              : plural(post.opposeCount || 0, {
                  one: 'Oppose (# oppose)',
                  other: 'Oppose (# opposes)',
                })
          }
          accessibilityHint=""
          hitSlop={POST_CTRL_HITSLOP}>
          <CCUnLike
            fixedColor={
              post.viewer?.oppose ? t.atoms.text.color : t.palette.gray_17
            }
            active={!!post.viewer?.oppose}
            size={big ? 24 : 17}
            style={big && {marginBottom: 2}}
            strokeWidth={1.5}
          />
          {typeof post.opposeCount !== 'undefined' && post.opposeCount > 0 ? (
            <Text
              testID="opposeCount"
              style={[
                [
                  big ? a.text_md : {fontSize: 15},
                  a.user_select_none,
                  post.viewer?.oppose
                    ? [t.atoms.text, a.font_bold]
                    : t.atoms.text_sub,
                ],
              ]}>
              {formatCount(post.opposeCount)}
            </Text>
          ) : undefined}
        </Pressable>

        <RepostButton
          isReposted={!!post.viewer?.repost}
          repostCount={post.repostCount}
          onRepost={onRepost}
          onQuote={onQuote}
          big={big}
        />
      </View>
    </View>
  )
}
BigFigurePostCtrls = memo(BigFigurePostCtrls)
export {BigFigurePostCtrls}

const styles = StyleSheet.create({
  item: {
    flexDirection: 'row',
    marginBottom: 5,
  },
  text: {
    fontSize: 15,
    position: 'absolute',
    left: 20,
    top: 0,
  },
  text2: {
    fontSize: 15,
    position: 'absolute',
    left: 30,
    top: 4,
  },
})
