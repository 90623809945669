import React from 'react'
import {StyleProp, ViewStyle} from 'react-native'
import {Path} from 'react-native-svg'

import {useTheme} from '#/alf'
import {SdlSVG, useThemeIconColor} from './ProfileIcons'

interface TopBarIconsProps {
  size?: number
  style?: StyleProp<ViewStyle>
  active?: boolean
  leftNavColor?: string
  isCircle?: boolean
  color?: string
}

export const MenuIcon = (props: TopBarIconsProps) => {
  const color = useThemeIconColor('#15161B', '#ffffff')
  return (
    <SdlSVG
      defaultSize={24}
      size={props.size}
      style={[{transform: [{translateY: 2}]}, props.style]}>
      <Path d="M3 5H21" stroke={color} strokeWidth="2" strokeLinecap="round" />
      <Path d="M3 12H21" stroke={color} strokeWidth="2" strokeLinecap="round" />
      <Path d="M3 19H21" stroke={color} strokeWidth="2" strokeLinecap="round" />
    </SdlSVG>
  )
}

export const BookMarkIcon = (props: TopBarIconsProps) => {
  const color = useThemeIconColor('#333333', '#ffffff')
  const t = useTheme()
  return (
    <SdlSVG
      defaultSize={24}
      size={props.size}
      style={[{transform: [{translateY: 1}]}, props.style]}>
      {props?.active ? (
        <Path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M4.99707 19.5035V6.99832C4.99707 5.34078 6.34078 3.99707 7.99832 3.99707H16.0017C17.6592 3.99707 19.0029 5.34078 19.0029 6.99832V19.5035C19.0031 19.6911 18.8983 19.8629 18.7316 19.9487C18.5648 20.0345 18.3641 20.0198 18.2116 19.9107L12.2911 15.6809C12.117 15.5563 11.883 15.5563 11.7089 15.6809L5.7884 19.9097C5.63605 20.0187 5.43554 20.0335 5.26887 19.9479C5.10219 19.8624 4.99729 19.6909 4.99707 19.5035Z"
          fill={props?.leftNavColor ?? t.palette.primary}
          stroke={props?.leftNavColor ?? t.palette.primary}
          strokeWidth={props.isCircle ? '2' : '1.5'}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      ) : (
        <Path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M4.99707 19.5035V6.99832C4.99707 5.34078 6.34078 3.99707 7.99832 3.99707H16.0017C17.6592 3.99707 19.0029 5.34078 19.0029 6.99832V19.5035C19.0031 19.6911 18.8983 19.8629 18.7316 19.9487C18.5648 20.0345 18.3641 20.0198 18.2116 19.9107L12.2911 15.6809C12.117 15.5563 11.883 15.5563 11.7089 15.6809L5.7884 19.9097C5.63605 20.0187 5.43554 20.0335 5.26887 19.9479C5.10219 19.8624 4.99729 19.6909 4.99707 19.5035Z"
          stroke={props.color || color}
          strokeWidth={props.isCircle ? '2' : '1.5'}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      )}
    </SdlSVG>
  )
}

export const InviteIcon = (props: TopBarIconsProps) => {
  const color = useThemeIconColor('#292D32', '#fff')

  return (
    <SdlSVG
      defaultSize={25}
      size={props.size}
      style={[{transform: [{translateY: 1}]}, props.style]}>
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.3403 14.1586C10.7884 14.1586 9.53027 12.9005 9.53027 11.3486C9.53027 9.79665 10.7884 8.53857 12.3403 8.53857C13.8922 8.53857 15.1503 9.79665 15.1503 11.3486C15.1503 12.9005 13.8922 14.1586 12.3403 14.1586Z"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <Path
        d="M17.5304 20.6285C17.5304 18.2985 15.2104 16.3984 12.3404 16.3984C9.47039 16.3984 7.15039 18.2885 7.15039 20.6285"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <Path
        d="M12.3799 22.4287C7.12988 22.4287 2.87988 18.1787 2.87988 12.9287C2.87988 7.67871 7.12988 3.42871 12.3799 3.42871C13.6899 3.42871 14.9399 3.6887 16.0799 4.1687C15.9499 4.5687 15.8799 4.98871 15.8799 5.42871C15.8799 6.17871 16.0899 6.88871 16.4599 7.48871C16.6599 7.82871 16.9199 8.13868 17.2199 8.39868C17.9199 9.03868 18.8499 9.42871 19.8799 9.42871C20.3199 9.42871 20.7399 9.35869 21.1299 9.21869C21.6099 10.3587 21.8799 11.6187 21.8799 12.9287C21.8799 18.1787 17.6299 22.4287 12.3799 22.4287Z"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <Path
        d="M23.7599 6.3587C23.6699 6.7587 23.5099 7.14871 23.2999 7.48871C22.8199 8.29871 22.0499 8.92869 21.1299 9.21869C20.7399 9.35869 20.3199 9.42871 19.8799 9.42871C18.8499 9.42871 17.9199 9.03868 17.2199 8.39868C16.9199 8.13868 16.6599 7.82871 16.4599 7.48871C16.0899 6.88871 15.8799 6.17871 15.8799 5.42871C15.8799 4.98871 15.9499 4.5687 16.0799 4.1687C16.2699 3.5887 16.5899 3.06873 17.0099 2.63873C17.7399 1.88873 18.7599 1.42871 19.8799 1.42871C21.0599 1.42871 22.1299 1.93873 22.8499 2.75873C23.4899 3.46873 23.8799 4.40871 23.8799 5.42871C23.8799 5.74871 23.8399 6.0587 23.7599 6.3587Z"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <Path
        d="M21.3696 5.40869H18.3896"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <Path
        d="M19.8799 3.94873V6.93872"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </SdlSVG>
  )
}

export const AssetsIcon = (props: TopBarIconsProps) => {
  return (
    <SdlSVG
      defaultSize={24}
      size={props.size}
      style={[{transform: [{translateY: 1}]}, props.style]}>
      <Path
        d="M1.90529 18.5934L1.745 7.74453C1.745 6.37603 2.5845 5.15698 3.861 4.67398L13.1095 1.14307C14.5355 0.602567 16.065 1.6606 16.065 3.1901V7.56009"
        stroke="#191503"
        strokeWidth="1.8"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <Path
        d="M23.38 16.4528C23.38 16.7748 23.1055 17.0382 22.7562 17.0499H20.6533C19.9795 17.0499 19.3619 16.5875 19.3057 15.9553C19.2683 15.5865 19.418 15.2411 19.6801 15.0011C19.9109 14.7787 20.2291 14.6499 20.5785 14.6499H22.7562C23.1055 14.6616 23.38 14.925 23.38 15.247V16.4528Z"
        fill="#191503"
      />
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16.3881 16.2644C16.2694 14.9827 16.7572 13.7872 17.5974 12.9579C18.3627 12.1806 19.4086 11.74 20.527 11.74H21.0459C20.9704 10.4197 19.8641 9.35997 18.526 9.35997H5.324C3.93762 9.35997 2.8 10.4976 2.8 11.884V19.6235C2.8 21.0099 3.93762 22.1475 5.324 22.1475H18.526C19.7654 22.1475 20.806 21.2383 21.0127 20.055H20.665C18.6319 20.055 16.5859 18.5685 16.3881 16.2644ZM22.8285 20.055C22.6107 22.2344 20.7609 23.9475 18.526 23.9475H5.324C2.9435 23.9475 1 22.004 1 19.6235V11.884C1 9.50348 2.9435 7.55997 5.324 7.55997H18.526C20.8583 7.55997 22.7711 9.4256 22.8476 11.74C22.8492 11.7878 22.85 11.8358 22.85 11.884V13.54H21.05H20.527C19.883 13.54 19.2965 13.793 18.871 14.23C18.388 14.7015 18.112 15.38 18.181 16.1045C18.2845 17.3465 19.423 18.255 20.665 18.255H21.05H22.85V19.6235C22.85 19.769 22.8427 19.913 22.8285 20.055Z"
        fill="#191503"
      />
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.3125 12.7351C5.3125 12.0999 5.82737 11.5851 6.4625 11.5851H10.2958C10.931 11.5851 11.4458 12.0999 11.4458 12.7351C11.4458 13.3702 10.931 13.8851 10.2958 13.8851H6.4625C5.82737 13.8851 5.3125 13.3702 5.3125 12.7351Z"
        fill="black"
      />
    </SdlSVG>
  )
}
