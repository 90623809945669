import React, {memo, useCallback, useMemo} from 'react'
import {
  Pressable,
  type StyleProp,
  StyleSheet,
  View,
  type ViewStyle,
} from 'react-native'
import {AppBskyFeedDefs, AppBskyFeedPost, AtUri} from '@atproto/api'
import {plural} from '@lingui/macro'

import {POST_CTRL_HITSLOP} from '#/lib/constants'
import {useHaptics} from '#/lib/haptics'
import {makeProfileLink} from '#/lib/routes/links'
import {toShareUrl} from '#/lib/strings/url-helpers'
import {Shadow} from '#/state/cache/types'
import {useFeedFeedbackContext} from '#/state/feed-feedback'
import {useModalControls} from '#/state/modals'
import {
  usePostLikeMutationQueue,
  usePostOpposeMutationQueue,
} from '#/state/queries/post'
import {useRequireAuth, useSession} from '#/state/session'
import {
  ProgressGuideAction,
  useProgressGuideControls,
} from '#/state/shell/progress-guide'
import {CcComment, CcLike, CcShare, CcTrending} from '#/view/icons/FeedIcons'
import {formatHandler} from '#/screens/Onboarding/util'
import {atoms as a, useTheme} from '#/alf'
import {formatCount} from '../numeric/format'
import {Text} from '../text/Text'

let CirclePostCtrls = ({
  big,
  post,
  record,
  feedContext,
  // richText,
  style,
  onPressReply,
  logContext,
  showRightBt,
  isMemoCard,
}: {
  big?: boolean
  post: Shadow<AppBskyFeedDefs.PostView>
  record: AppBskyFeedPost.Record
  showRightBt?: boolean
  feedContext?: string | undefined
  style?: StyleProp<ViewStyle>
  onPressReply: () => void
  isMemoCard?: boolean
  logContext: 'FeedItem' | 'PostThreadItem' | 'Post'
}): React.ReactNode => {
  const {currentAccount} = useSession()
  const [queueLike, queueUnlike] = usePostLikeMutationQueue(post, logContext)
  const [queueUnoppose] = usePostOpposeMutationQueue(post, logContext)
  const requireAuth = useRequireAuth()
  const {sendInteraction} = useFeedFeedbackContext()
  const {captureAction} = useProgressGuideControls()
  const playHaptic = useHaptics()

  const postHeat = useMemo(() => {
    const popularityScore = calculatePostPopularity({
      views: post.viewCount || 0,
      likes: post.likeCount || 0,
      comments: post.replyCount || 0,
      count1st: 1,
      countCircle: 1,
      count2nd: 1,
      createdAt: record.createdAt,
    })
    if (popularityScore > 1) {
      let heat = formatCount(popularityScore)
      return heat === '0' ? '' : heat
    } else if (popularityScore > 0.0001) {
      return popularityScore
    } else {
      return ''
    }
  }, [post.likeCount, post.replyCount, post.viewCount, record.createdAt])

  const {openModal} = useModalControls()

  const onShare = useCallback(() => {
    const urip = new AtUri(post.uri)
    const href = makeProfileLink(post.author, 'post', urip.rkey)
    const url = toShareUrl(href)

    let target = ''

    if (post.record?.finEntities?.length) {
      post.record?.finEntities.forEach(element => {
        target += `#${element.name} `
      })
    }

    openModal({
      name: 'share-tea',
      avatar: post.author.avatar || '',
      displayName: record.hideAuthor
        ? 'Anonymous'
        : post.author.displayName || formatHandler(post.author.handle),
      url: url,
      image: post?.embed?.images?.[0]?.thumb || '',
      content: post.record?.title || post.record?.text || '',
      replyCount: post.replyCount || 0,
      repostCount: post.repostCount || 0,
      likeCount: post.likeCount || 0,
      target,
    })
    sendInteraction({
      item: post.uri,
      event: 'app.bsky.feed.defs#interactionShare',
      feedContext,
    })
  }, [
    post.uri,
    post.author,
    post.record?.finEntities,
    post.record?.title,
    post.record?.text,
    post?.embed?.images,
    post.replyCount,
    post.repostCount,
    post.likeCount,
    openModal,
    record.hideAuthor,
    sendInteraction,
    feedContext,
  ])

  const onPressToggleLike = React.useCallback(async () => {
    try {
      if (!post.viewer?.like) {
        playHaptic()
        sendInteraction({
          item: post.uri,
          event: 'app.bsky.feed.defs#interactionLike',
          feedContext,
        })
        captureAction(ProgressGuideAction.Like)
        await queueLike()
        if (post.viewer?.oppose) {
          await queueUnoppose()
        }
      } else {
        await queueUnlike()
      }
    } catch (e: any) {
      if (e?.name !== 'AbortError') {
        throw e
      }
    }
  }, [
    playHaptic,
    post.uri,
    post.viewer?.like,
    queueLike,
    queueUnlike,
    sendInteraction,
    captureAction,
    feedContext,
    post.viewer?.oppose,
    queueUnoppose,
  ])

  const t = useTheme()

  if (isMemoCard) {
    return (
      <View style={{flexDirection: 'row', width: 92, marginLeft: 10}}>
        <View
          style={[
            a.flex_row,
            a.justify_between,
            a.align_center,
            style,
            !showRightBt && {
              flex: 1,
              justifyContent: 'space-between',
              paddingRight: 15,
            },
          ]}>
          <Pressable
            testID="likeBtn"
            style={styles.item}
            onPress={() => requireAuth(() => onPressToggleLike())}
            accessibilityLabel={
              post.viewer?.like
                ? plural(post.likeCount || 0, {
                    one: 'Unlike (# like)',
                    other: 'Unlike (# likes)',
                  })
                : plural(post.likeCount || 0, {
                    one: 'Like (# like)',
                    other: 'Like (# likes)',
                  })
            }
            accessibilityHint=""
            hitSlop={POST_CTRL_HITSLOP}>
            <CcLike
              active={!!post.viewer?.like && !!currentAccount?.did}
              isMemoCard
              size={17}
              strokeWidth={1.5}
            />
            {typeof post.likeCount !== 'undefined' && post.likeCount > 0 ? (
              <Text
                testID="likeCount"
                style={[
                  [
                    big ? a.text_md : {fontSize: 15},
                    a.user_select_none,
                    styles.text,
                    {color: 'black'},
                    post.viewer?.like && !!currentAccount?.did
                      ? {fontWeight: 'bold'}
                      : null,
                  ],
                ]}>
                {formatCount(post.likeCount)}
              </Text>
            ) : undefined}
          </Pressable>

          <Pressable
            testID="replyBtn"
            style={styles.item}
            onPress={() => {
              if (!post.viewer?.replyDisabled) {
                requireAuth(() => onPressReply())
              }
            }}
            accessibilityLabel={plural(post.replyCount || 0, {
              one: 'Reply (# reply)',
              other: 'Reply (# replies)',
            })}
            accessibilityHint=""
            hitSlop={POST_CTRL_HITSLOP}>
            <CcComment
              size={17}
              isMemoCard
              strokeWidth={1.5}
              strokeWidth2={2}
            />
            {typeof post.replyCount !== 'undefined' && post.replyCount > 0 ? (
              <Text
                style={[
                  styles.text,
                  big ? a.text_md : {fontSize: 15},
                  a.user_select_none,
                  {color: 'black'},
                ]}>
                {formatCount(post.replyCount)}
              </Text>
            ) : undefined}
          </Pressable>
        </View>
      </View>
    )
  }

  return (
    <View
      style={{
        flexDirection: 'row',
        width: '100%',
        borderWidth: 0,
        marginBottom: -5,
      }}>
      <View
        style={[
          a.flex_row,
          a.justify_between,
          a.align_center,
          style,
          !showRightBt && {flex: 1, justifyContent: 'space-between'},
        ]}>
        <Pressable
          testID="replyBtn"
          style={styles.item}
          onPress={() => {
            if (!post.viewer?.replyDisabled) {
              requireAuth(() => onPressReply())
            }
          }}
          accessibilityLabel={plural(post.replyCount || 0, {
            one: 'Reply (# reply)',
            other: 'Reply (# replies)',
          })}
          accessibilityHint=""
          hitSlop={POST_CTRL_HITSLOP}>
          <CcComment
            size={24}
            fixedColor={t.atoms.text.color}
            strokeWidth={1.5}
            strokeWidth2={2}
          />
          {typeof post.replyCount !== 'undefined' && post.replyCount > 0 ? (
            <Text
              style={[
                styles.text2,
                t.atoms.text,
                big ? a.text_md : {fontSize: 15},
                a.user_select_none,
              ]}>
              {formatCount(post.replyCount)}
            </Text>
          ) : undefined}
        </Pressable>

        <Pressable
          testID="likeBtn"
          style={styles.item}
          onPress={() => requireAuth(() => onPressToggleLike())}
          accessibilityLabel={
            post.viewer?.like
              ? plural(post.likeCount || 0, {
                  one: 'Unlike (# like)',
                  other: 'Unlike (# likes)',
                })
              : plural(post.likeCount || 0, {
                  one: 'Like (# like)',
                  other: 'Like (# likes)',
                })
          }
          accessibilityHint=""
          hitSlop={POST_CTRL_HITSLOP}>
          <CcLike
            size={24}
            active={!!post.viewer?.like && !!currentAccount?.did}
            fixedColor={t.atoms.text.color}
            strokeWidth={1.5}
          />
          {typeof post.likeCount !== 'undefined' && post.likeCount > 0 ? (
            <Text
              testID="likeCount"
              style={[
                [
                  big ? a.text_md : {fontSize: 15},
                  a.user_select_none,
                  styles.text2,
                  t.atoms.text,
                  post.viewer?.like && !!currentAccount?.did
                    ? {fontWeight: 'bold'}
                    : null,
                ],
              ]}>
              {formatCount(post.likeCount)}
            </Text>
          ) : undefined}
        </Pressable>

        <Pressable
          testID="replyBtn"
          style={styles.item}
          onPress={() => {}}
          accessibilityLabel={plural(post.replyCount || 0, {
            one: 'Reply (# reply)',
            other: 'Reply (# replies)',
          })}
          accessibilityHint=""
          hitSlop={POST_CTRL_HITSLOP}>
          <CcTrending />
          {typeof post.replyCount !== 'undefined' && post.replyCount > 0 ? (
            <Text
              style={[
                t.atoms.text,
                styles.text2,
                big ? a.text_md : {fontSize: 15},
                a.user_select_none,
              ]}>
              {postHeat}
            </Text>
          ) : undefined}
        </Pressable>

        <Pressable
          testID="replyBtn"
          style={styles.item}
          onPress={onShare}
          accessibilityLabel={plural(post.replyCount || 0, {
            one: 'Reply (# reply)',
            other: 'Reply (# replies)',
          })}
          accessibilityHint=""
          hitSlop={POST_CTRL_HITSLOP}>
          <CcShare />
        </Pressable>
      </View>
    </View>
  )
}
CirclePostCtrls = memo(CirclePostCtrls)
export {CirclePostCtrls}
const styles = StyleSheet.create({
  item: {
    flexDirection: 'row',
    marginBottom: 5,
  },
  text: {
    fontSize: 15,
    position: 'absolute',
    left: 20,
    top: 0,
  },
  text2: {
    fontSize: 15,
    position: 'absolute',
    left: 30,
    top: 4,
  },
})

export function calculatePostPopularity(parm: {
  views: number
  likes: number
  comments: number
  count1st: number
  countCircle: number
  count2nd: number
  createdAt: string
}): number {
  const {views, likes, comments, count1st, countCircle, count2nd, createdAt} =
    parm
  const specificTimeStamp = new Date(createdAt).getTime()
  const nowTimeStamp = new Date().getTime()
  const timeDifference = nowTimeStamp - specificTimeStamp
  const hoursElapsed = Math.floor(timeDifference / (1000 * 60 * 60))

  // Ensure hoursElapsed does not exceed 168 hours
  const t = Math.min(hoursElapsed, 168)

  // Calculate v1, v2, and v3
  const v1 = views * 1 + likes * 3 + comments * 9
  const v2 = views * 1 + likes * 2 + comments * 4
  const v3 = views * 1 + likes * 1 + comments * 1

  // Calculate the popularity count
  const popularityCount = Math.pow(
    v1 ** count1st + v2 ** countCircle + v3 ** count2nd,
    3,
  )

  // Calculate the exponential decay sum
  const decaySum = t * Math.exp(-0.18 * t)

  // Calculate final popularity score
  const popularity = popularityCount * decaySum

  return Math.ceil(popularity * 10)
}
