import React from 'react'
import {Pressable, Text, View} from 'react-native'
import {Trans} from '@lingui/macro'
import {useQueryClient} from '@tanstack/react-query'

import {useModalControls} from '#/state/modals'
import {CONNECTIONS_RQKEY} from '#/state/queries/connections'
import {TelegramBindStep} from '#/view/com/modals/TelegramModal/config'
import {UserAvatar} from '#/view/com/util/UserAvatar'
import {AddFriendsIcon} from '#/view/icons/ModalIcons'
import {formatHandler} from '#/screens/Onboarding/util'
import {atoms as a, useTheme} from '#/alf'
import {IconCircle} from '#/components/IconCircle'
import {ArrowUpLeftIcon} from '#/components/icons/StakeIcons'
import {Telegram} from '#/components/icons/Telegram'
import {ContactItemProps} from '.'

export interface RecommendItemProps extends ContactItemProps {
  source?: string
  isPending?: boolean
}

export default function RecommendItem(props: RecommendItemProps) {
  const t = useTheme()
  const {openModal} = useModalControls()
  const queryClient = useQueryClient()

  return (
    <View style={[a.py_sm, a.flex_row, a.justify_between, a.gap_md]}>
      <View style={[a.flex_row, a.flex_1, a.align_center, a.gap_md]}>
        <UserAvatar size={56} avatar={props?.avatar} />
        <View style={[a.flex_col, a.flex_1, a.gap_xs, a.w_full]}>
          <View style={[a.flex_row, a.align_center, a.gap_xs]}>
            <Text
              style={[a.text_md, a.font_bold, t.atoms.text]}
              numberOfLines={1}>
              {props?.nickname}
            </Text>
            {props.isMutual ? (
              <View style={[a.flex_row, a.align_center, a.gap_xs]}>
                <IconCircle
                  style={[{width: 16, height: 16, backgroundColor: '#2aabee'}]}
                  icon={Telegram}
                  iconStyle={{color: t.palette.white}}
                  size="xxs"
                />
                <Text style={[a.text_xs, t.atoms.text]}>
                  <Trans>Mutual</Trans>
                </Text>
              </View>
            ) : (
              ''
            )}
          </View>
          <Text style={[a.text_sm, t.atoms.text_sub]}>
            @{formatHandler(props?.username ?? '')}
          </Text>
          <Text style={[a.text_sm, {color: t.palette.primary_active}]}>
            {props?.source}
          </Text>
        </View>
      </View>
      <View style={[a.mt_md]}>
        {props?.isPending ? (
          <View style={[a.flex_row, a.gap_xs]}>
            <ArrowUpLeftIcon style={{marginTop: 2}} />
            <Text
              style={[a.text_xs, a.font_semibold, {color: t.palette.gray_10}]}>
              Request sent
            </Text>
          </View>
        ) : (
          <Pressable
            accessibilityRole="button"
            role="button"
            style={[
              a.flex_row,
              a.align_center,
              a.justify_center,
              {
                height: 32,
                width: 70,
                borderRadius: 32,
                backgroundColor: t.palette.primary,
              },
            ]}
            onPress={() => {
              if (props?.from === 'sipz') {
                openModal({
                  name: 'send-friends-request',
                  selectedUser: props,
                  from: 'recommend',
                  onCallBack: () => {},
                })
              } else if (props?.from === 'telegram') {
                openModal({
                  name: 'telegram',
                  currentStep: TelegramBindStep.SendMessage,
                  userIds: [props?.id],
                  onCallBack: () => {
                    queryClient.invalidateQueries({
                      queryKey: [CONNECTIONS_RQKEY.recommendList],
                    })
                    queryClient.invalidateQueries({
                      queryKey: [CONNECTIONS_RQKEY.requestList],
                    })
                  },
                })
              }
            }}>
            <AddFriendsIcon />
            <Text style={[a.text_sm, a.font_semibold]}>
              <Trans>{props?.from === 'sipz' ? 'Add' : 'Invite'}</Trans>
            </Text>
          </Pressable>
        )}
      </View>
    </View>
  )
}
