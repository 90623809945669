import React, {useCallback} from 'react'
import {
  StyleProp,
  StyleSheet,
  Text,
  TouchableOpacity,
  View,
  ViewStyle,
} from 'react-native'
import {Trans} from '@lingui/macro'

import {useModalControls} from '#/state/modals'
import {useSession} from '#/state/session'
import {useComposerControls} from '#/state/shell'
import {ComposerOpts} from '#/state/shell/composer'
import {useSelectedFeed} from '#/state/shell/selected-feed'
import {
  ApplyEmptyIcon,
  EngagementEmptyIcon,
  HeartEmptyIcon,
  SearchEmptyIcon,
  TeaEmptyIcon,
} from '#/view/icons/EmptyIcons'
import {useTheme} from '#/alf'
import {useTgStore} from './sdlStore/TgStore'
import {useTwStore} from './sdlStore/TwStore'

interface IEmptyBox {
  icon: string
  message: string
  isMe?: boolean
  viewStyles?: StyleProp<ViewStyle>
}

export function EmptyBox({icon, message, isMe, viewStyles}: IEmptyBox) {
  const {openComposer} = useComposerControls(),
    {openModal} = useModalControls(),
    selectedFeed = useSelectedFeed(),
    {bind: hasBindTelegram} = useTgStore(),
    {isBoundTw} = useTwStore()

  const validatePublish = useCallback(
    async (opts: ComposerOpts) => {
      if (selectedFeed?.includes('circle')) {
        if (!hasBindTelegram) {
          openModal({name: 'telegram'})
          return
        }
      } else {
        if (!hasBindTelegram && !isBoundTw) {
          openModal({name: 'link-social'})
          return
        }
      }
      openComposer(opts)
    },
    [selectedFeed, openComposer, hasBindTelegram, openModal, isBoundTw],
  )
  const {currentAccount} = useSession()
  const iconDom = () => {
    switch (icon) {
      case 'heart':
      case 'notifications':
        return <HeartEmptyIcon />

      case 'engagement':
        return <EngagementEmptyIcon />

      case 'search':
        return <SearchEmptyIcon />

      case 'apply':
        return <ApplyEmptyIcon />

      default:
        return <TeaEmptyIcon />
    }
  }

  const onPressLink = useCallback(() => {
    switch (icon) {
      // case value:

      //   break;

      default:
        validatePublish({})
    }
  }, [icon, validatePublish])

  const t = useTheme()

  return (
    <View style={[styles.page, viewStyles]}>
      {iconDom()}
      <Text style={styles.message}>{message}</Text>
      {currentAccount?.did && isMe && icon === 'tea' && (
        <TouchableOpacity
          accessibilityRole="button"
          style={{
            justifyContent: 'center',
            height: 48,
            backgroundColor: t.palette.primary,
            paddingHorizontal: 50,
            borderRadius: 100,
            marginTop: 30,
          }}
          onPress={onPressLink}>
          <Text style={styles.link}>
            <Trans>Spill</Trans>
          </Text>
        </TouchableOpacity>
      )}
    </View>
  )
}
const styles = StyleSheet.create({
  page: {
    paddingTop: 100,
    width: '100%',
    paddingVertical: 10,
    alignItems: 'center',
  },
  message: {
    fontSize: 16,
    color: '#979797',
    textAlign: 'center',
  },
  link: {
    fontSize: 18,
    fontWeight: 'bold',
    color: 'black',
  },
})
