import React from 'react'
import {ImageBackground, Pressable, Text, View} from 'react-native'
import {FontAwesomeIcon} from '@fortawesome/react-native-fontawesome'
import {msg, Trans} from '@lingui/macro'
import {useLingui} from '@lingui/react'

import {useTheme as useThemeContext} from '#/lib/ThemeContext'
import {isMobile} from '#/platform/detection'
import {useWebMediaQueries} from 'lib/hooks/useWebMediaQueries'
import {Logo} from '#/view/icons/Logo'
import {ErrorBoundary} from 'view/com/util/ErrorBoundary'
import {atoms as a, useTheme} from '#/alf'
// import {AppLanguageDropdown} from '#/components/AppLanguageDropdown'
import {Button, ButtonText} from '#/components/Button'
// import {InlineLinkText} from '#/components/Link'
// import {CenteredView} from '../util/Views'
import {Footer} from './Footer'

export const SplashScreen = ({
  onDismiss,
  onPressSignin,
  onPressCreateAccount,
}: {
  onDismiss?: () => void
  onPressSignin: () => void
  onPressCreateAccount: () => void
}) => {
  const {_} = useLingui()
  const t = useTheme()
  const {isTabletOrMobile: isMobileWeb} = useWebMediaQueries()
  const theme = useThemeContext()

  return (
    <>
      {onDismiss && (
        <Pressable
          accessibilityRole="button"
          style={{
            position: 'absolute',
            top: 20,
            right: 20,
            padding: 20,
            zIndex: 100,
          }}
          onPress={onDismiss}>
          <FontAwesomeIcon
            icon="x"
            size={24}
            style={{
              color: String(t.atoms.text.color),
            }}
          />
        </Pressable>
      )}

      <View
        style={[
          a.h_full_vh,
          // a.flex_1,
          {
            justifyContent: 'space-between',
            display: 'flex',
            position: 'relative',
            background:
              theme.colorScheme === 'dark'
                ? 'linear-gradient(0.41deg, #000000 1.13%, #312906 98.66%)'
                : 'linear-gradient(0deg, #FFFFFF 37.3%, #FFE262 100%)',
          },
        ]}>
        {isMobile && (
          <ImageBackground
            resizeMode="stretch"
            source={
              theme.colorScheme === 'dark'
                ? require('../../../../assets/imgs/bg_login_dark.png')
                : require('../../../../assets/imgs/bg_login.png')
            }
            style={{
              height: '100%',
              width: '100%',
              justifyContent: 'center',
              alignItems: 'center',
              paddingTop: 300,
              position: 'absolute',
              top: 0,
              left: 0,
            }}
          />
        )}
        <View
          testID="noSessionView"
          style={[
            // a.h_full,
            a.justify_center,
            a.flex_1,
            // @ts-ignore web only
            // {paddingBottom: '20vh'},
            isMobileWeb && a.pb_5xl,
            t.atoms.border_contrast_medium,
            a.align_center,
          ]}>
          <ErrorBoundary>
            <View style={[a.justify_center, a.align_center, {paddingTop: 200}]}>
              <Logo />
              <Text style={[{fontSize: 20, marginVertical: 20}, t.atoms.text]}>
                <Trans>A realm without secrets</Trans>
              </Text>
            </View>

            <View
              testID="signinOrCreateAccount"
              style={[a.w_full, {maxWidth: 320, marginTop: 40}]}>
              <Button
                testID="createAccountButton"
                onPress={onPressCreateAccount}
                accessibilityRole="button"
                label={_(msg`Create new account`)}
                accessibilityHint={_(
                  msg`Opens flow to create a new Sipz account`,
                )}
                style={[
                  a.mx_xl,
                  a.mb_xl,
                  {
                    borderWidth: 1,
                    borderColor: '#FCD010',
                    shadowRadius: 16,
                    shadowOpacity: 0.5,
                    shadowColor: '#FCD010',
                    elevation: 16,
                  },
                ]}
                size="large"
                variant="solid"
                color="primary">
                <ButtonText style={{color: 'black'}}>
                  <Trans>Create account</Trans>
                </ButtonText>
              </Button>
              <Button
                testID="signInButton"
                onPress={onPressSignin}
                label={_(msg`Login`)}
                accessibilityHint={_(
                  msg`Opens flow to sign into your existing Sipz account`,
                )}
                style={[
                  a.mx_xl,
                  a.mb_xl,
                  a.shadow_md,
                  {
                    borderWidth: 1,
                    borderColor: '#FCD010',
                    backgroundColor: '#fffbeb',
                  },
                ]}
                hoverStyle={{
                  backgroundColor: '#ffec96',
                }}
                size="large"
                variant="solid"
                color="secondary">
                <ButtonText style={{color: 'black'}}>
                  <Trans>Login</Trans>
                </ButtonText>
              </Button>
            </View>

            <View style={[a.pt_md]}>
              <Text style={[a.text_xs, {color: t.palette.black_25}]}>
                Beta test limited for early users
              </Text>
            </View>
          </ErrorBoundary>
        </View>
        <Footer style={[a.mb_2xl]} />
      </View>
    </>
  )
}

// function Footer() {
//   const t = useTheme()
//   const {_} = useLingui()

//   return (
//     <View
//       style={[
//         a.absolute,
//         a.inset_0,
//         {top: 'auto'},
//         a.p_xl,
//         a.border_t,
//         a.flex_row,
//         a.flex_wrap,
//         a.gap_xl,
//         a.flex_1,
//         t.atoms.border_contrast_medium,
//       ]}>
//       <InlineLinkText
//         label={_(msg`Learn more about Sipz`)}
//         to="https://bsky.social">
//         <Trans>Business</Trans>
//       </InlineLinkText>
//       <InlineLinkText
//         label={_(msg`Read the Sipz blog`)}
//         to="https://bsky.social/about/blog">
//         <Trans>Blog</Trans>
//       </InlineLinkText>
//       <InlineLinkText
//         label={_(msg`See jobs at Sipz`)}
//         to="https://bsky.social/about/join">
//         <Trans>Jobs</Trans>
//       </InlineLinkText>

//       <View style={a.flex_1} />

//       <AppLanguageDropdown />
//     </View>
//   )
// }
