import React from 'react'
import {StyleSheet, View} from 'react-native'
import {msg} from '@lingui/macro'
import {useLingui} from '@lingui/react'
import {RouteProp, useRoute} from '@react-navigation/native'

import {FEED_CIRCLE_URI} from '#/lib/constants'
import {CommonNavigatorParams} from '#/lib/routes/types'
import {FeedDescriptor} from '#/state/queries/post-feed'
import {FeedPage} from '#/view/com/feeds/CircleFeedPage'
import {EmptyBox} from '#/view/com/util/EmptyBox'
import {CenteredView} from '#/view/com/util/Views'
import CircleHeader from './CircleHeader'

export function Detail() {
  const route = useRoute<RouteProp<CommonNavigatorParams, 'CircleDetail'>>()

  const feed: FeedDescriptor = `feedgen|${FEED_CIRCLE_URI}`

  const {_} = useLingui()

  return (
    <CenteredView>
      <CircleHeader circleId={route.params.id} />
      <FeedPage
        testID="customFeedPage"
        isPageFocused={true}
        feed={feed}
        feedParams={{circleids: route.params.id}}
        renderEndOfFeed={() => (
          <View style={styles.emptyBox}>
            <EmptyBox
              isMe
              icon={'tea'}
              message={_(
                msg`Oops! No More tea yet.Invite your Telegram contacts or spill some tea now.`,
              )}
            />
          </View>
        )}
        renderEmptyState={() => (
          <View style={styles.emptyBox}>
            <EmptyBox
              isMe
              icon={'tea'}
              message={_(
                msg`Oops! No tea yet.Invite your Telegram contacts or spill some tea now.`,
              )}
            />
          </View>
        )}
      />
    </CenteredView>
  )
}

const styles = StyleSheet.create({
  emptyBox: {
    width: '100%',
    paddingVertical: 10,
    paddingHorizontal: 30,
    marginTop: -80,
  },
})
