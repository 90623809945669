import React from 'react'
import {View} from 'react-native'
import {AppBskyActorDefs as ActorDefs} from '@atproto/api'
import {msg} from '@lingui/macro'
import {useLingui} from '@lingui/react'

import {useProfileFollowsQuery} from '#/state/queries/profile-follows'
import {useSession} from '#/state/session'
import {atoms as a} from '#/alf'
import {ScrollView} from '../../modals/util'
import {EmptyBox} from '../../util/EmptyBox'
import {ItemProps} from '.'
import {Actor} from './Actor'

export const Following = ({onPress, selecteds}: ItemProps) => {
  const {currentAccount} = useSession()
  const {_} = useLingui()
  const {
    data,
    // isLoading: isFollowersLoading,
    // isFetchingNextPage,
    // hasNextPage,
    // fetchNextPage,
    // error,
    // refetch,
  } = useProfileFollowsQuery(currentAccount?.did)

  const follows = React.useMemo(() => {
    if (data?.pages) {
      return data.pages.flatMap(page => page.follows)
    }
    return []
  }, [data])
  const renderItem = ({item}: {item: ActorDefs.ProfileViewBasic}) => {
    return (
      <Actor
        actor={item}
        onPress={onPress}
        style={[a.mt_md, a.justify_between]}
        isSelected={
          selecteds && selecteds?.findIndex(o => o?.did === item?.did) >= 0
        }
      />
    )
  }

  return (
    <ScrollView style={[a.mt_md, a.flex_1]}>
      {follows.length ? (
        <View style={{height: 410, width: '100%'}}>
          {follows.map(item => renderItem({item}))}
        </View>
      ) : (
        <View style={{paddingBottom: 100}}>
          <EmptyBox
            icon={''}
            message={_(msg`No content available at the moment`)}
          />
        </View>
      )}
    </ScrollView>
  )
}
