import React, {useState} from 'react'
import {Text, View} from 'react-native'
import {ComAtprotoServerCircleQueryApproveInfos} from '@atproto/api'
import {msg, Trans} from '@lingui/macro'
import {useLingui} from '@lingui/react'

import {useAgent} from '#/state/session'
import * as Toast from '#/view/com/util/Toast'
import {UserAvatar} from '#/view/com/util/UserAvatar'
import {atoms as a, useTheme} from '#/alf'
import {Button, ButtonText} from '#/components/Button'

export default function CircleRequestItem(
  props: ComAtprotoServerCircleQueryApproveInfos.ApproveInfo,
) {
  const t = useTheme()
  const {_} = useLingui()
  const agent = useAgent()

  const [loading, setLaoding] = useState<boolean>(false)

  const onAccept = async () => {
    if (!loading) {
      setLaoding(true)
      await agent.com.atproto.server.circleAcceptInvitation({
        circle_id: +route.params.id,
      })
      Toast.show(_(msg`You have accept this request success!`))
      setLaoding(false)
    }
  }

  return (
    <View style={[a.flex_row, a.gap_md, a.pt_sm]}>
      <UserAvatar
        style={[a.rounded_full, a.overflow_hidden]}
        avatar={props.tgPhoto}
        size={44}
        name={props.tgFirstname || props.tgLastname}
      />
      <View
        style={[
          a.flex_row,
          a.flex_1,
          a.justify_between,
          a.pb_lg,
          a.border_b,
          t.atoms.border_contrast_low,
        ]}>
        <View style={[a.justify_between, a.gap_xs]}>
          <View style={[a.flex_row, a.gap_sm]}>
            {props.actor ? (
              <Text style={[a.font_bold, {fontSize: 14}]}>
                {props.actor.displayName}
              </Text>
            ) : (
              <Text style={[a.font_bold, {fontSize: 14}]}>
                {props.tgFirstname} {props.tgLastname}
              </Text>
            )}
            <Text>{props.create_dt}</Text>
          </View>
          {props.circleName && (
            <Text
              style={[
                t.atoms.text_contrast_high,
                a.font_heavy,
                {fontSize: 12},
              ]}>
              #{props.circleName}
            </Text>
          )}
          {props.joinReason && (
            <Text style={{fontSize: 12}}>{props.joinReason}</Text>
          )}
        </View>
        <Button
          style={[{marginLeft: 'auto'}, a.self_start, a.mt_md]}
          label="circle detail"
          color="primary"
          variant="solid"
          size="small"
          onPress={onAccept}>
          <ButtonText>
            <Trans>Accept</Trans>
          </ButtonText>
        </Button>
      </View>
    </View>
  )
}
