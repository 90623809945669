import React from 'react'
import {msg} from '@lingui/macro'
import {useLingui} from '@lingui/react'
// import {Text, TextStyle} from 'react-native'
// import {StyleProp} from 'react-native-web-swiper'

export function TextLinearGradient({
  text,
  corner,
  colors,
  textStyle,
}: {
  text: string
  colors: string[]
  corner?: string
  textStyle?: any
}) {
  const {_} = useLingui()
  const backgroundImage = `linear-gradient(${
    corner || 'to right'
  }, ${colors.join(',')}`

  const _text = _(msg`${text}`)

  return (
    <div
      style={{
        ...textStyle,
        backgroundImage,
        backgroundClip: 'text',
        color: 'transparent',
      }}>
      {_text}
    </div>
  )
}
